:root {
  --color-extended-black-0: rgba(0, 0, 0, 0);
  --color-extended-black-03: rgba(0, 0, 0, .03);
  --color-extended-black-05: rgba(0, 0, 0, .05);
  --color-extended-black-10: rgba(0, 0, 0, .1);
  --color-extended-black-100: #000;
  --color-extended-black-15: rgba(0, 0, 0, .15);
  --color-extended-black-20: rgba(0, 0, 0, .2);
  --color-extended-black-30: rgba(0, 0, 0, .3);
  --color-extended-black-40: rgba(0, 0, 0, .4);
  --color-extended-black-50: rgba(0, 0, 0, .5);
  --color-extended-black-60: rgba(0, 0, 0, .6);
  --color-extended-black-70: rgba(0, 0, 0, .7);
  --color-extended-black-80: rgba(0, 0, 0, .8);
  --color-extended-black-85: rgba(0, 0, 0, .85);
  --color-extended-black-90: rgba(0, 0, 0, .9);
  --color-extended-black-95: rgba(0, 0, 0, .95);
  --color-extended-black-97: rgba(0, 0, 0, .97);
  --color-extended-blue-100: #ebf1ff;
  --color-extended-blue-1000: #002dd6;
  --color-extended-blue-1100: #0027b8;
  --color-extended-blue-1200: #029;
  --color-extended-blue-1300: #001b7a;
  --color-extended-blue-1400: #00145c;
  --color-extended-blue-200: #cdf;
  --color-extended-blue-300: #adc9ff;
  --color-extended-blue-400: #8fb4ff;
  --color-extended-blue-500: #70a0ff;
  --color-extended-blue-600: #528bff;
  --color-extended-blue-700: #335fff;
  --color-extended-blue-800: #1446ff;
  --color-extended-blue-900: #0035f5;
  --color-extended-gray-100: #f7f7f8;
  --color-extended-gray-1000: #202227;
  --color-extended-gray-1100: #191a1f;
  --color-extended-gray-1200: #121215;
  --color-extended-gray-200: #eeeff1;
  --color-extended-gray-300: #e6e7ea;
  --color-extended-gray-400: #d5d7dd;
  --color-extended-gray-500: #bcbec8;
  --color-extended-gray-600: #6a6f81;
  --color-extended-gray-700: #494d5a;
  --color-extended-gray-800: #353741;
  --color-extended-gray-900: #272930;
  --color-extended-green-100: #d0fbe3;
  --color-extended-green-1000: #015f35;
  --color-extended-green-1100: #004a29;
  --color-extended-green-1200: #01381f;
  --color-extended-green-1300: #0a2612;
  --color-extended-green-1400: #00160c;
  --color-extended-green-200: #b0f6d0;
  --color-extended-green-300: #8ee9b6;
  --color-extended-green-400: #65d79d;
  --color-extended-green-500: #39c582;
  --color-extended-green-600: #1db16a;
  --color-extended-green-700: #0c9b5a;
  --color-extended-green-800: #01854b;
  --color-extended-green-900: #027240;
  --color-extended-magenta-100: #fde8f9;
  --color-extended-magenta-1000: #8a0ca7;
  --color-extended-magenta-1100: #6e0785;
  --color-extended-magenta-1200: #540467;
  --color-extended-magenta-1300: #3c024a;
  --color-extended-magenta-1400: #23042a;
  --color-extended-magenta-200: #fdddfd;
  --color-extended-magenta-300: #f5c8fc;
  --color-extended-magenta-400: #efaafa;
  --color-extended-magenta-500: #e88bf8;
  --color-extended-magenta-600: #df6af7;
  --color-extended-magenta-700: #d540f5;
  --color-extended-magenta-800: #c00ae8;
  --color-extended-magenta-900: #a50ec8;
  --color-extended-orange-100: #fde8d8;
  --color-extended-orange-1000: #903300;
  --color-extended-orange-1100: #732800;
  --color-extended-orange-1200: #571e00;
  --color-extended-orange-1300: #3c1500;
  --color-extended-orange-1400: #210d01;
  --color-extended-orange-200: #fcdec7;
  --color-extended-orange-300: #fbceae;
  --color-extended-orange-400: #fbb286;
  --color-extended-orange-500: #fa945a;
  --color-extended-orange-600: #f57630;
  --color-extended-orange-700: #dc6423;
  --color-extended-orange-800: #c05014;
  --color-extended-orange-900: #a9430b;
  --color-extended-pink-100: #ffe6f2;
  --color-extended-pink-1000: #991d58;
  --color-extended-pink-1100: #630335;
  --color-extended-pink-1200: #630335;
  --color-extended-pink-1300: #460424;
  --color-extended-pink-1400: #2b0215;
  --color-extended-pink-200: #fedcec;
  --color-extended-pink-300: #facae0;
  --color-extended-pink-400: #f7adcf;
  --color-extended-pink-500: #f38fb8;
  --color-extended-pink-600: #ee72a1;
  --color-extended-pink-700: #e74e90;
  --color-extended-pink-800: #cd377b;
  --color-extended-pink-900: #b42b6a;
  --color-extended-purple-100: #f1e7ff;
  --color-extended-purple-1000: #6c21c6;
  --color-extended-purple-1100: #5614a5;
  --color-extended-purple-1200: #410886;
  --color-extended-purple-1300: #2d0460;
  --color-extended-purple-1400: #1c023b;
  --color-extended-purple-200: #e9defb;
  --color-extended-purple-300: #e2cefd;
  --color-extended-purple-400: #d4b5fc;
  --color-extended-purple-500: #c59cf9;
  --color-extended-purple-600: #b682f7;
  --color-extended-purple-700: #a766f5;
  --color-extended-purple-800: #9348f0;
  --color-extended-purple-900: #822ee7;
  --color-extended-red-100: #ffeae9;
  --color-extended-red-1000: #a41109;
  --color-extended-red-1100: #830f07;
  --color-extended-red-1200: #640c05;
  --color-extended-red-1300: #470804;
  --color-extended-red-1400: #280603;
  --color-extended-red-200: #ffdfde;
  --color-extended-red-300: #f7cdcb;
  --color-extended-red-400: #f3b2ae;
  --color-extended-red-500: #ee9791;
  --color-extended-red-600: #eb7973;
  --color-extended-red-700: #e75750;
  --color-extended-red-800: #df281f;
  --color-extended-red-900: #c5120a;
  --color-extended-turquoise-100: #c8f6ff;
  --color-extended-turquoise-1000: #055a6a;
  --color-extended-turquoise-1100: #044754;
  --color-extended-turquoise-1200: #02353f;
  --color-extended-turquoise-1300: #02252c;
  --color-extended-turquoise-1400: #021418;
  --color-extended-turquoise-200: #a3effe;
  --color-extended-turquoise-300: #7ee5f8;
  --color-extended-turquoise-400: #5cd2e8;
  --color-extended-turquoise-500: #39bdd8;
  --color-extended-turquoise-600: #16a9c7;
  --color-extended-turquoise-700: #0494b2;
  --color-extended-turquoise-800: #127d98;
  --color-extended-turquoise-900: #056c82;
  --color-extended-white-0: rgba(255, 255, 255, 0);
  --color-extended-white-03: rgba(255, 255, 255, .03);
  --color-extended-white-05: rgba(255, 255, 255, .05);
  --color-extended-white-10: rgba(255, 255, 255, .1);
  --color-extended-white-100: #fff;
  --color-extended-white-15: rgba(255, 255, 255, .15);
  --color-extended-white-20: rgba(255, 255, 255, .2);
  --color-extended-white-30: rgba(255, 255, 255, .3);
  --color-extended-white-40: rgba(255, 255, 255, .4);
  --color-extended-white-50: rgba(255, 255, 255, .5);
  --color-extended-white-60: rgba(255, 255, 255, .6);
  --color-extended-white-70: rgba(255, 255, 255, .7);
  --color-extended-white-80: rgba(255, 255, 255, .8);
  --color-extended-white-85: rgba(255, 255, 255, .85);
  --color-extended-white-90: rgba(255, 255, 255, .9);
  --color-extended-white-95: rgba(255, 255, 255, .95);
  --color-extended-white-97: rgba(255, 255, 255, .97);
  --color-extended-yellow-100: #fdecc1;
  --color-extended-yellow-1000: #6b4c00;
  --color-extended-yellow-1100: #533d02;
  --color-extended-yellow-1200: #3f2d01;
  --color-extended-yellow-1300: #2c1f00;
  --color-extended-yellow-1400: #181100;
  --color-extended-yellow-200: #fae4aa;
  --color-extended-yellow-300: #f7d273;
  --color-extended-yellow-400: #f2ba2a;
  --color-extended-yellow-500: #dba61f;
  --color-extended-yellow-600: #c49315;
  --color-extended-yellow-700: #ae800b;
  --color-extended-yellow-800: #976c00;
  --color-extended-yellow-900: #815d00;
  --config-text-font: "Builder Sans";
  --config-text-interval: calc(var(--size-100) * var(--config-text-scale) * var(--config-text-nominal-scale));
  --config-text-inverse-scale: calc(1 / var(--config-text-nominal-scale));
  --config-text-nominal-scale: 1;
  --config-text-scale: 1;
  --config-theme-is-foundation: true;
  --config-theme-is-uiblox: false;
  --config-theme-name: Foundation;
  --config-uiinterval: 4;
  --config-uiscale: 1px;
  --config-uistroke-base: 1;
  --dark-mode-action-alert-background: var(--dark-mode-system-alert);
  --dark-mode-action-alert-border: var(--dark-mode-none);
  --dark-mode-action-alert-foreground: var(--dark-mode-content-emphasis);
  --dark-mode-action-emphasis-background: var(--dark-mode-system-emphasis);
  --dark-mode-action-emphasis-border: var(--dark-mode-none);
  --dark-mode-action-emphasis-foreground: var(--dark-mode-content-emphasis);
  --dark-mode-action-link-background: var(--dark-mode-none);
  --dark-mode-action-link-border: var(--dark-mode-none);
  --dark-mode-action-link-foreground: var(--dark-mode-content-emphasis);
  --dark-mode-action-soft-emphasis-background: rgba(51, 95, 255, .4);
  --dark-mode-action-soft-emphasis-border: var(--dark-mode-none);
  --dark-mode-action-soft-emphasis-foreground: var(--color-extended-blue-100);
  --dark-mode-action-standard-background: var(--dark-mode-shift-300);
  --dark-mode-action-standard-border: var(--dark-mode-none);
  --dark-mode-action-standard-foreground: var(--dark-mode-content-emphasis);
  --dark-mode-action-sub-emphasis-background: var(--dark-mode-system-contrast);
  --dark-mode-action-sub-emphasis-border: var(--dark-mode-none);
  --dark-mode-action-sub-emphasis-foreground: var(--light-mode-content-emphasis);
  --dark-mode-action-subtle-background: var(--dark-mode-none);
  --dark-mode-action-subtle-border: var(--dark-mode-content-default);
  --dark-mode-action-subtle-foreground: var(--dark-mode-content-emphasis);
  --dark-mode-common-heavy-divider: var(--color-extended-black-50);
  --dark-mode-common-navigation-bar: var(--dark-mode-surface-0);
  --dark-mode-common-scrim: var(--color-extended-black-50);
  --dark-mode-common-shadow: var(--color-extended-black-50);
  --dark-mode-common-shimmer: var(--dark-mode-shift-300);
  --dark-mode-content-default: var(--color-extended-gray-400);
  --dark-mode-content-emphasis: var(--color-extended-gray-100);
  --dark-mode-content-link: var(--color-extended-blue-600);
  --dark-mode-content-muted: var(--color-extended-gray-500);
  --dark-mode-none: rgba(0, 0, 0, 0);
  --dark-mode-over-media-0: rgba(18, 18, 21, .92);
  --dark-mode-over-media-100: rgba(25, 26, 31, .92);
  --dark-mode-over-media-200: rgba(32, 34, 39, .92);
  --dark-mode-over-media-300: rgba(39, 41, 48, .92);
  --dark-mode-selection-end: var(--color-extended-white-50);
  --dark-mode-selection-start: var(--color-extended-white-100);
  --dark-mode-shift-100: rgba(208, 217, 251, .04);
  --dark-mode-shift-200: rgba(208, 217, 251, .08);
  --dark-mode-shift-300: rgba(208, 217, 251, .12);
  --dark-mode-shift-400: rgba(208, 217, 251, .16);
  --dark-mode-state-hover: var(--color-extended-white-15);
  --dark-mode-state-idle: var(--color-extended-white-0);
  --dark-mode-state-press: var(--color-extended-white-20);
  --dark-mode-stroke-default: var(--dark-mode-shift-300);
  --dark-mode-stroke-emphasis: var(--dark-mode-shift-400);
  --dark-mode-stroke-muted: var(--dark-mode-shift-200);
  --dark-mode-surface-0: var(--color-extended-gray-1200);
  --dark-mode-surface-100: var(--color-extended-gray-1100);
  --dark-mode-surface-200: var(--color-extended-gray-1000);
  --dark-mode-surface-300: var(--color-extended-gray-900);
  --dark-mode-system-alert: var(--color-extended-red-800);
  --dark-mode-system-contrast: var(--dark-mode-content-emphasis);
  --dark-mode-system-emphasis: var(--color-extended-blue-700);
  --dark-mode-system-neutral: var(--color-extended-gray-500);
  --dark-mode-system-success: var(--color-extended-green-500);
  --dark-mode-system-warning: var(--color-extended-yellow-400);
  --ease-expressive-in: cubic-bezier(.25, 0, .5, -1);
  --ease-expressive-out: cubic-bezier(.25, 1.5, .5, 1);
  --ease-linear: cubic-bezier(0, 0, 1, 1);
  --ease-standard-in: cubic-bezier(.8, 0, .4, 1);
  --ease-standard-out: cubic-bezier(.2, 0, 0, 1);
  --font-size-1000: calc(var(--config-text-interval) * 10);
  --font-size-1100: calc(var(--config-text-interval) * 11);
  --font-size-1200: calc(var(--config-text-interval) * 12);
  --font-size-1300: calc(var(--config-text-interval) * 13);
  --font-size-1400: calc(var(--config-text-interval) * 14);
  --font-size-1500: calc(var(--config-text-interval) * 15);
  --font-size-1600: calc(var(--config-text-interval) * 16);
  --font-size-1700: calc(var(--config-text-interval) * 17);
  --font-size-1800: calc(var(--config-text-interval) * 18);
  --font-size-1900: calc(var(--config-text-interval) * 19);
  --font-size-200: calc(var(--config-text-interval) * 2);
  --font-size-2000: calc(var(--config-text-interval) * 20);
  --font-size-2100: calc(var(--config-text-interval) * 21);
  --font-size-2200: calc(var(--config-text-interval) * 22);
  --font-size-2300: calc(var(--config-text-interval) * 23);
  --font-size-2400: calc(var(--config-text-interval) * 24);
  --font-size-250: calc(var(--config-text-interval) * 2.5);
  --font-size-2500: calc(var(--config-text-interval) * 25);
  --font-size-2600: calc(var(--config-text-interval) * 26);
  --font-size-2700: calc(var(--config-text-interval) * 27);
  --font-size-2800: calc(var(--config-text-interval) * 28);
  --font-size-2900: calc(var(--config-text-interval) * 29);
  --font-size-300: calc(var(--config-text-interval) * 3);
  --font-size-3000: calc(var(--config-text-interval) * 30);
  --font-size-350: calc(var(--config-text-interval) * 3.5);
  --font-size-400: calc(var(--config-text-interval) * 4);
  --font-size-500: calc(var(--config-text-interval) * 5);
  --font-size-600: calc(var(--config-text-interval) * 6);
  --font-size-700: calc(var(--config-text-interval) * 7);
  --font-size-800: calc(var(--config-text-interval) * 8);
  --font-size-900: calc(var(--config-text-interval) * 9);
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --gap-large: calc(var(--size-400));
  --gap-medium: calc(var(--size-300));
  --gap-small: calc(var(--size-200));
  --gap-xlarge: calc(var(--size-500));
  --gap-xsmall: calc(var(--size-100));
  --gap-xxlarge: calc(var(--size-600));
  --gap-xxsmall: calc(var(--size-50));
  --gutter-large: calc(var(--size-400));
  --gutter-medium: calc(var(--size-300));
  --gutter-small: calc(var(--size-200));
  --gutter-xlarge: calc(var(--size-500));
  --gutter-xxlarge: calc(var(--size-600));
  --letter-spacing-default: 0em;
  --letter-spacing-loose: .01em;
  --letter-spacing-tight: -.01em;
  --light-mode-action-alert-background: var(--light-mode-system-alert);
  --light-mode-action-alert-border: var(--light-mode-none);
  --light-mode-action-alert-foreground: var(--dark-mode-content-emphasis);
  --light-mode-action-emphasis-background: var(--light-mode-system-emphasis);
  --light-mode-action-emphasis-border: var(--light-mode-none);
  --light-mode-action-emphasis-foreground: var(--dark-mode-content-emphasis);
  --light-mode-action-link-background: var(--light-mode-none);
  --light-mode-action-link-border: var(--light-mode-none);
  --light-mode-action-link-foreground: var(--light-mode-content-emphasis);
  --light-mode-action-soft-emphasis-background: rgba(51, 95, 255, .3);
  --light-mode-action-soft-emphasis-border: var(--light-mode-none);
  --light-mode-action-soft-emphasis-foreground: var(--color-extended-blue-1400);
  --light-mode-action-standard-background: var(--light-mode-shift-300);
  --light-mode-action-standard-border: var(--light-mode-none);
  --light-mode-action-standard-foreground: var(--light-mode-content-emphasis);
  --light-mode-action-sub-emphasis-background: var(--light-mode-system-contrast);
  --light-mode-action-sub-emphasis-border: var(--light-mode-none);
  --light-mode-action-sub-emphasis-foreground: var(--dark-mode-content-emphasis);
  --light-mode-action-subtle-background: var(--light-mode-none);
  --light-mode-action-subtle-border: var(--light-mode-content-default);
  --light-mode-action-subtle-foreground: var(--light-mode-content-emphasis);
  --light-mode-common-heavy-divider: var(--light-mode-shift-100);
  --light-mode-common-navigation-bar: var(--light-mode-surface-0);
  --light-mode-common-scrim: var(--color-extended-black-50);
  --light-mode-common-shadow: var(--color-extended-black-50);
  --light-mode-common-shimmer: var(--light-mode-shift-300);
  --light-mode-content-default: var(--color-extended-gray-700);
  --light-mode-content-emphasis: var(--color-extended-gray-1000);
  --light-mode-content-link: var(--color-extended-blue-800);
  --light-mode-content-muted: var(--color-extended-gray-600);
  --light-mode-none: rgba(0, 0, 0, 0);
  --light-mode-over-media-0: rgba(255, 255, 255, .92);
  --light-mode-over-media-100: rgba(247, 247, 248, .92);
  --light-mode-over-media-200: rgba(255, 255, 255, .92);
  --light-mode-over-media-300: rgba(247, 247, 248, .92);
  --light-mode-selection-end: var(--color-extended-gray-500);
  --light-mode-selection-start: var(--color-extended-gray-500);
  --light-mode-shift-100: rgba(27, 37, 75, .04);
  --light-mode-shift-200: rgba(27, 37, 75, .08);
  --light-mode-shift-300: rgba(27, 37, 75, .12);
  --light-mode-shift-400: rgba(27, 37, 75, .16);
  --light-mode-state-hover: var(--color-extended-black-15);
  --light-mode-state-idle: var(--color-extended-black-0);
  --light-mode-state-press: var(--color-extended-black-20);
  --light-mode-stroke-default: var(--light-mode-shift-300);
  --light-mode-stroke-emphasis: var(--light-mode-shift-400);
  --light-mode-stroke-muted: var(--light-mode-shift-200);
  --light-mode-surface-0: var(--color-extended-white-100);
  --light-mode-surface-100: var(--color-extended-gray-100);
  --light-mode-surface-200: var(--color-extended-white-100);
  --light-mode-surface-300: var(--color-extended-gray-100);
  --light-mode-system-alert: var(--color-extended-red-800);
  --light-mode-system-contrast: var(--light-mode-content-emphasis);
  --light-mode-system-emphasis: var(--color-extended-blue-700);
  --light-mode-system-neutral: var(--color-extended-gray-700);
  --light-mode-system-success: var(--color-extended-green-500);
  --light-mode-system-warning: var(--color-extended-yellow-400);
  --line-height-100: calc(100% * var(--config-text-inverse-scale));
  --line-height-120: calc(120% * var(--config-text-inverse-scale));
  --line-height-130: calc(130% * var(--config-text-inverse-scale));
  --line-height-140: calc(140% * var(--config-text-inverse-scale));
  --margin-large: calc(var(--size-1200));
  --margin-medium: calc(var(--size-600));
  --margin-small: calc(var(--size-500));
  --padding-large: calc(var(--size-400));
  --padding-medium: calc(var(--size-300));
  --padding-small: calc(var(--size-200));
  --padding-xlarge: calc(var(--size-500));
  --padding-xsmall: calc(var(--size-100));
  --padding-xxlarge: calc(var(--size-600));
  --padding-xxsmall: calc(var(--size-50));
  --platform-base: var(--config-uiinterval);
  --platform-rem: calc(var(--size-200));
  --platform-scale: var(--config-uiscale);
  --radius-circle: 9999;
  --radius-large: calc(var(--size-400));
  --radius-medium: calc(var(--size-200));
  --radius-none: calc(var(--size-0));
  --radius-small: calc(var(--size-100));
  --radius-xsmall: calc(var(--size-50));
  --size-0: 0;
  --size-100: calc(var(--config-uiscale) * var(--config-uiinterval));
  --size-1000: calc(var(--size-100) * 10);
  --size-1100: calc(var(--size-100) * 11);
  --size-1200: calc(var(--size-100) * 12);
  --size-1300: calc(var(--size-100) * 13);
  --size-1400: calc(var(--size-100) * 14);
  --size-150: calc(var(--size-100) * 1.5);
  --size-1500: calc(var(--size-100) * 15);
  --size-1600: calc(var(--size-100) * 16);
  --size-1700: calc(var(--size-100) * 17);
  --size-1800: calc(var(--size-100) * 18);
  --size-1900: calc(var(--size-100) * 19);
  --size-200: calc(var(--size-100) * 2);
  --size-2000: calc(var(--size-100) * 20);
  --size-2100: calc(var(--size-100) * 21);
  --size-2200: calc(var(--size-100) * 22);
  --size-2300: calc(var(--size-100) * 23);
  --size-2400: calc(var(--size-100) * 24);
  --size-250: calc(var(--size-100) * 2.5);
  --size-2500: calc(var(--size-100) * 25);
  --size-2600: calc(var(--size-100) * 26);
  --size-2700: calc(var(--size-100) * 27);
  --size-2800: calc(var(--size-100) * 28);
  --size-2900: calc(var(--size-100) * 29);
  --size-300: calc(var(--size-100) * 3);
  --size-3000: calc(var(--size-100) * 30);
  --size-350: calc(var(--size-100) * 3.5);
  --size-400: calc(var(--size-100) * 4);
  --size-50: calc(var(--size-100) * .5);
  --size-500: calc(var(--size-100) * 5);
  --size-600: calc(var(--size-100) * 6);
  --size-700: calc(var(--size-100) * 7);
  --size-800: calc(var(--size-100) * 8);
  --size-900: calc(var(--size-100) * 9);
  --stroke-standard: calc(var(--config-uistroke-base) * var(--config-uiscale));
  --stroke-thick: calc(var(--stroke-standard) * 1.5);
  --stroke-thicker: calc(var(--stroke-standard) * 3);
  --stroke-thin: calc(var(--stroke-standard) * .5);
  --time-100: .1;
  --time-1000: 1;
  --time-200: .2;
  --time-300: .3;
  --time-400: .4;
  --time-50: .05;
  --time-500: .5;
  --time-600: .6;
  --time-700: .7;
  --time-800: .8;
  --time-900: .9;
  --typography-body-large-font: var(--font-weight-400) var(--font-size-400) / var(--line-height-140) var(--config-text-font);
  --typography-body-large-letter-spacing: var(--letter-spacing-default);
  --typography-body-medium-font: var(--font-weight-400) var(--font-size-350) / var(--line-height-140) var(--config-text-font);
  --typography-body-medium-letter-spacing: var(--letter-spacing-default);
  --typography-body-small-font: var(--font-weight-400) var(--font-size-300) / var(--line-height-140) var(--config-text-font);
  --typography-body-small-letter-spacing: var(--letter-spacing-default);
  --typography-caption-large-font: var(--font-weight-600) var(--font-size-350) / var(--line-height-140) var(--config-text-font);
  --typography-caption-large-letter-spacing: var(--letter-spacing-default);
  --typography-caption-medium-font: var(--font-weight-600) var(--font-size-300) / var(--line-height-140) var(--config-text-font);
  --typography-caption-medium-letter-spacing: var(--letter-spacing-default);
  --typography-caption-small-font: var(--font-weight-600) var(--font-size-250) / var(--line-height-140) var(--config-text-font);
  --typography-caption-small-letter-spacing: var(--letter-spacing-default);
  --typography-display-large-font: var(--font-weight-700) var(--font-size-2000) / var(--line-height-120) var(--config-text-font);
  --typography-display-large-letter-spacing: var(--letter-spacing-tight);
  --typography-display-medium-font: var(--font-weight-700) var(--font-size-1400) / var(--line-height-120) var(--config-text-font);
  --typography-display-medium-letter-spacing: var(--letter-spacing-tight);
  --typography-display-small-font: var(--font-weight-700) var(--font-size-1000) / var(--line-height-120) var(--config-text-font);
  --typography-display-small-letter-spacing: var(--letter-spacing-tight);
  --typography-heading-large-font: var(--font-weight-700) var(--font-size-700) / var(--line-height-120) var(--config-text-font);
  --typography-heading-large-letter-spacing: var(--letter-spacing-tight);
  --typography-heading-medium-font: var(--font-weight-700) var(--font-size-600) / var(--line-height-120) var(--config-text-font);
  --typography-heading-medium-letter-spacing: var(--letter-spacing-tight);
  --typography-heading-small-font: var(--font-weight-700) var(--font-size-500) / var(--line-height-120) var(--config-text-font);
  --typography-heading-small-letter-spacing: var(--letter-spacing-tight);
  --typography-label-large-font: var(--font-weight-600) var(--font-size-400) / var(--line-height-100) var(--config-text-font);
  --typography-label-large-letter-spacing: var(--letter-spacing-default);
  --typography-label-medium-font: var(--font-weight-600) var(--font-size-350) / var(--line-height-100) var(--config-text-font);
  --typography-label-medium-letter-spacing: var(--letter-spacing-default);
  --typography-label-small-font: var(--font-weight-600) var(--font-size-300) / var(--line-height-100) var(--config-text-font);
  --typography-label-small-letter-spacing: var(--letter-spacing-default);
  --typography-title-large-font: var(--font-weight-700) var(--font-size-400) / var(--line-height-140) var(--config-text-font);
  --typography-title-large-letter-spacing: var(--letter-spacing-default);
  --typography-title-medium-font: var(--font-weight-700) var(--font-size-350) / var(--line-height-140) var(--config-text-font);
  --typography-title-medium-letter-spacing: var(--letter-spacing-default);
  --typography-title-small-font: var(--font-weight-700) var(--font-size-300) / var(--line-height-140) var(--config-text-font);
  --typography-title-small-letter-spacing: var(--letter-spacing-default);
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light dark;
  --color-action-alert-background: var(--light-mode-action-alert-background);
  --color-action-alert-border: var(--light-mode-action-alert-border);
  --color-action-alert-foreground: var(--light-mode-action-alert-foreground);
  --color-action-emphasis-background: var(--light-mode-action-emphasis-background);
  --color-action-emphasis-border: var(--light-mode-action-emphasis-border);
  --color-action-emphasis-foreground: var(--light-mode-action-emphasis-foreground);
  --color-action-link-background: var(--light-mode-action-link-background);
  --color-action-link-border: var(--light-mode-action-link-border);
  --color-action-link-foreground: var(--light-mode-action-link-foreground);
  --color-action-soft-emphasis-background: var(--light-mode-action-soft-emphasis-background);
  --color-action-soft-emphasis-border: var(--light-mode-action-soft-emphasis-border);
  --color-action-soft-emphasis-foreground: var(--light-mode-action-soft-emphasis-foreground);
  --color-action-standard-background: var(--light-mode-action-standard-background);
  --color-action-standard-border: var(--light-mode-action-standard-border);
  --color-action-standard-foreground: var(--light-mode-action-standard-foreground);
  --color-action-sub-emphasis-background: var(--light-mode-action-sub-emphasis-background);
  --color-action-sub-emphasis-border: var(--light-mode-action-sub-emphasis-border);
  --color-action-sub-emphasis-foreground: var(--light-mode-action-sub-emphasis-foreground);
  --color-action-subtle-background: var(--light-mode-action-subtle-background);
  --color-action-subtle-border: var(--light-mode-action-subtle-border);
  --color-action-subtle-foreground: var(--light-mode-action-subtle-foreground);
  --color-common-heavy-divider: var(--light-mode-common-heavy-divider);
  --color-common-navigation-bar: var(--light-mode-common-navigation-bar);
  --color-common-scrim: var(--light-mode-common-scrim);
  --color-common-shadow: var(--light-mode-common-shadow);
  --color-common-shimmer: var(--light-mode-common-shimmer);
  --color-content-default: var(--light-mode-content-default);
  --color-content-emphasis: var(--light-mode-content-emphasis);
  --color-content-link: var(--light-mode-content-link);
  --color-content-muted: var(--light-mode-content-muted);
  --color-none: var(--light-mode-none);
  --color-over-media-0: var(--light-mode-over-media-0);
  --color-over-media-100: var(--light-mode-over-media-100);
  --color-over-media-200: var(--light-mode-over-media-200);
  --color-over-media-300: var(--light-mode-over-media-300);
  --color-selection-end: var(--light-mode-selection-end);
  --color-selection-start: var(--light-mode-selection-start);
  --color-shift-100: var(--light-mode-shift-100);
  --color-shift-200: var(--light-mode-shift-200);
  --color-shift-300: var(--light-mode-shift-300);
  --color-shift-400: var(--light-mode-shift-400);
  --color-state-hover: var(--light-mode-state-hover);
  --color-state-idle: var(--light-mode-state-idle);
  --color-state-press: var(--light-mode-state-press);
  --color-stroke-default: var(--light-mode-stroke-default);
  --color-stroke-emphasis: var(--light-mode-stroke-emphasis);
  --color-stroke-muted: var(--light-mode-stroke-muted);
  --color-surface-0: var(--light-mode-surface-0);
  --color-surface-100: var(--light-mode-surface-100);
  --color-surface-200: var(--light-mode-surface-200);
  --color-surface-300: var(--light-mode-surface-300);
  --color-system-alert: var(--light-mode-system-alert);
  --color-system-contrast: var(--light-mode-system-contrast);
  --color-system-emphasis: var(--light-mode-system-emphasis);
  --color-system-neutral: var(--light-mode-system-neutral);
  --color-system-success: var(--light-mode-system-success);
  --color-system-warning: var(--light-mode-system-warning);
  --config-color-mode-is-dark: false;
  --config-color-mode-is-light: true;
  --config-color-mode-name: Light;
  --inverse-action-alert-background: var(--dark-mode-action-alert-background);
  --inverse-action-alert-border: var(--dark-mode-action-alert-border);
  --inverse-action-alert-foreground: var(--dark-mode-action-alert-foreground);
  --inverse-action-emphasis-background: var(--dark-mode-action-emphasis-background);
  --inverse-action-emphasis-border: var(--dark-mode-action-emphasis-border);
  --inverse-action-emphasis-foreground: var(--dark-mode-action-emphasis-foreground);
  --inverse-action-link-background: var(--dark-mode-action-link-background);
  --inverse-action-link-border: var(--dark-mode-action-link-border);
  --inverse-action-link-foreground: var(--dark-mode-action-link-foreground);
  --inverse-action-soft-emphasis-background: var(--dark-mode-action-soft-emphasis-background);
  --inverse-action-soft-emphasis-border: var(--dark-mode-action-soft-emphasis-border);
  --inverse-action-soft-emphasis-foreground: var(--dark-mode-action-soft-emphasis-foreground);
  --inverse-action-standard-background: var(--dark-mode-action-standard-background);
  --inverse-action-standard-border: var(--dark-mode-action-standard-border);
  --inverse-action-standard-foreground: var(--dark-mode-action-standard-foreground);
  --inverse-action-sub-emphasis-background: var(--dark-mode-action-sub-emphasis-background);
  --inverse-action-sub-emphasis-border: var(--dark-mode-action-sub-emphasis-border);
  --inverse-action-sub-emphasis-foreground: var(--dark-mode-action-sub-emphasis-foreground);
  --inverse-action-subtle-background: var(--dark-mode-action-subtle-background);
  --inverse-action-subtle-border: var(--dark-mode-action-subtle-border);
  --inverse-action-subtle-foreground: var(--dark-mode-action-subtle-foreground);
  --inverse-common-heavy-divider: var(--dark-mode-common-heavy-divider);
  --inverse-common-navigation-bar: var(--dark-mode-common-navigation-bar);
  --inverse-common-scrim: var(--dark-mode-common-scrim);
  --inverse-common-shadow: var(--dark-mode-common-shadow);
  --inverse-common-shimmer: var(--dark-mode-common-shimmer);
  --inverse-content-default: var(--dark-mode-content-default);
  --inverse-content-emphasis: var(--dark-mode-content-emphasis);
  --inverse-content-link: var(--dark-mode-content-link);
  --inverse-content-muted: var(--dark-mode-content-muted);
  --inverse-none: var(--dark-mode-none);
  --inverse-over-media-0: var(--dark-mode-over-media-0);
  --inverse-over-media-100: var(--dark-mode-over-media-100);
  --inverse-over-media-200: var(--dark-mode-over-media-200);
  --inverse-over-media-300: var(--dark-mode-over-media-300);
  --inverse-selection-end: var(--dark-mode-selection-end);
  --inverse-selection-start: var(--dark-mode-selection-start);
  --inverse-shift-100: var(--dark-mode-shift-100);
  --inverse-shift-200: var(--dark-mode-shift-200);
  --inverse-shift-300: var(--dark-mode-shift-300);
  --inverse-shift-400: var(--dark-mode-shift-400);
  --inverse-state-hover: var(--dark-mode-state-hover);
  --inverse-state-idle: var(--dark-mode-state-idle);
  --inverse-state-press: var(--dark-mode-state-press);
  --inverse-stroke-default: var(--dark-mode-stroke-default);
  --inverse-stroke-emphasis: var(--dark-mode-stroke-emphasis);
  --inverse-stroke-muted: var(--dark-mode-stroke-muted);
  --inverse-surface-0: var(--dark-mode-surface-0);
  --inverse-surface-100: var(--dark-mode-surface-100);
  --inverse-surface-200: var(--dark-mode-surface-200);
  --inverse-surface-300: var(--dark-mode-surface-300);
  --inverse-system-alert: var(--dark-mode-system-alert);
  --inverse-system-contrast: var(--dark-mode-system-contrast);
  --inverse-system-emphasis: var(--dark-mode-system-emphasis);
  --inverse-system-neutral: var(--dark-mode-system-neutral);
  --inverse-system-success: var(--dark-mode-system-success);
  --inverse-system-warning: var(--dark-mode-system-warning);
}

@media (prefers-color-scheme: dark) {
  :root {
    --lightningcss-light: initial;
    --lightningcss-dark: ;
    --lightningcss-light: initial;
    --lightningcss-dark: ;
    color-scheme: light dark;
    --color-action-alert-background: var(--dark-mode-action-alert-background);
    --color-action-alert-border: var(--dark-mode-action-alert-border);
    --color-action-alert-foreground: var(--dark-mode-action-alert-foreground);
    --color-action-emphasis-background: var(--dark-mode-action-emphasis-background);
    --color-action-emphasis-border: var(--dark-mode-action-emphasis-border);
    --color-action-emphasis-foreground: var(--dark-mode-action-emphasis-foreground);
    --color-action-link-background: var(--dark-mode-action-link-background);
    --color-action-link-border: var(--dark-mode-action-link-border);
    --color-action-link-foreground: var(--dark-mode-action-link-foreground);
    --color-action-soft-emphasis-background: var(--dark-mode-action-soft-emphasis-background);
    --color-action-soft-emphasis-border: var(--dark-mode-action-soft-emphasis-border);
    --color-action-soft-emphasis-foreground: var(--dark-mode-action-soft-emphasis-foreground);
    --color-action-standard-background: var(--dark-mode-action-standard-background);
    --color-action-standard-border: var(--dark-mode-action-standard-border);
    --color-action-standard-foreground: var(--dark-mode-action-standard-foreground);
    --color-action-sub-emphasis-background: var(--dark-mode-action-sub-emphasis-background);
    --color-action-sub-emphasis-border: var(--dark-mode-action-sub-emphasis-border);
    --color-action-sub-emphasis-foreground: var(--dark-mode-action-sub-emphasis-foreground);
    --color-action-subtle-background: var(--dark-mode-action-subtle-background);
    --color-action-subtle-border: var(--dark-mode-action-subtle-border);
    --color-action-subtle-foreground: var(--dark-mode-action-subtle-foreground);
    --color-common-heavy-divider: var(--dark-mode-common-heavy-divider);
    --color-common-navigation-bar: var(--dark-mode-common-navigation-bar);
    --color-common-scrim: var(--dark-mode-common-scrim);
    --color-common-shadow: var(--dark-mode-common-shadow);
    --color-common-shimmer: var(--dark-mode-common-shimmer);
    --color-content-default: var(--dark-mode-content-default);
    --color-content-emphasis: var(--dark-mode-content-emphasis);
    --color-content-link: var(--dark-mode-content-link);
    --color-content-muted: var(--dark-mode-content-muted);
    --color-none: var(--dark-mode-none);
    --color-over-media-0: var(--dark-mode-over-media-0);
    --color-over-media-100: var(--dark-mode-over-media-100);
    --color-over-media-200: var(--dark-mode-over-media-200);
    --color-over-media-300: var(--dark-mode-over-media-300);
    --color-selection-end: var(--dark-mode-selection-end);
    --color-selection-start: var(--dark-mode-selection-start);
    --color-shift-100: var(--dark-mode-shift-100);
    --color-shift-200: var(--dark-mode-shift-200);
    --color-shift-300: var(--dark-mode-shift-300);
    --color-shift-400: var(--dark-mode-shift-400);
    --color-state-hover: var(--dark-mode-state-hover);
    --color-state-idle: var(--dark-mode-state-idle);
    --color-state-press: var(--dark-mode-state-press);
    --color-stroke-default: var(--dark-mode-stroke-default);
    --color-stroke-emphasis: var(--dark-mode-stroke-emphasis);
    --color-stroke-muted: var(--dark-mode-stroke-muted);
    --color-surface-0: var(--dark-mode-surface-0);
    --color-surface-100: var(--dark-mode-surface-100);
    --color-surface-200: var(--dark-mode-surface-200);
    --color-surface-300: var(--dark-mode-surface-300);
    --color-system-alert: var(--dark-mode-system-alert);
    --color-system-contrast: var(--dark-mode-system-contrast);
    --color-system-emphasis: var(--dark-mode-system-emphasis);
    --color-system-neutral: var(--dark-mode-system-neutral);
    --color-system-success: var(--dark-mode-system-success);
    --color-system-warning: var(--dark-mode-system-warning);
    --config-color-mode-is-dark: true;
    --config-color-mode-is-light: false;
    --config-color-mode-name: Dark;
    --inverse-action-alert-background: var(--light-mode-action-alert-background);
    --inverse-action-alert-border: var(--light-mode-action-alert-border);
    --inverse-action-alert-foreground: var(--light-mode-action-alert-foreground);
    --inverse-action-emphasis-background: var(--light-mode-action-emphasis-background);
    --inverse-action-emphasis-border: var(--light-mode-action-emphasis-border);
    --inverse-action-emphasis-foreground: var(--light-mode-action-emphasis-foreground);
    --inverse-action-link-background: var(--light-mode-action-link-background);
    --inverse-action-link-border: var(--light-mode-action-link-border);
    --inverse-action-link-foreground: var(--light-mode-action-link-foreground);
    --inverse-action-soft-emphasis-background: var(--light-mode-action-soft-emphasis-background);
    --inverse-action-soft-emphasis-border: var(--light-mode-action-soft-emphasis-border);
    --inverse-action-soft-emphasis-foreground: var(--light-mode-action-soft-emphasis-foreground);
    --inverse-action-standard-background: var(--light-mode-action-standard-background);
    --inverse-action-standard-border: var(--light-mode-action-standard-border);
    --inverse-action-standard-foreground: var(--light-mode-action-standard-foreground);
    --inverse-action-sub-emphasis-background: var(--light-mode-action-sub-emphasis-background);
    --inverse-action-sub-emphasis-border: var(--light-mode-action-sub-emphasis-border);
    --inverse-action-sub-emphasis-foreground: var(--light-mode-action-sub-emphasis-foreground);
    --inverse-action-subtle-background: var(--light-mode-action-subtle-background);
    --inverse-action-subtle-border: var(--light-mode-action-subtle-border);
    --inverse-action-subtle-foreground: var(--light-mode-action-subtle-foreground);
    --inverse-common-heavy-divider: var(--light-mode-common-heavy-divider);
    --inverse-common-navigation-bar: var(--light-mode-common-navigation-bar);
    --inverse-common-scrim: var(--light-mode-common-scrim);
    --inverse-common-shadow: var(--light-mode-common-shadow);
    --inverse-common-shimmer: var(--light-mode-common-shimmer);
    --inverse-content-default: var(--light-mode-content-default);
    --inverse-content-emphasis: var(--light-mode-content-emphasis);
    --inverse-content-link: var(--light-mode-content-link);
    --inverse-content-muted: var(--light-mode-content-muted);
    --inverse-none: var(--light-mode-none);
    --inverse-over-media-0: var(--light-mode-over-media-0);
    --inverse-over-media-100: var(--light-mode-over-media-100);
    --inverse-over-media-200: var(--light-mode-over-media-200);
    --inverse-over-media-300: var(--light-mode-over-media-300);
    --inverse-selection-end: var(--light-mode-selection-end);
    --inverse-selection-start: var(--light-mode-selection-start);
    --inverse-shift-100: var(--light-mode-shift-100);
    --inverse-shift-200: var(--light-mode-shift-200);
    --inverse-shift-300: var(--light-mode-shift-300);
    --inverse-shift-400: var(--light-mode-shift-400);
    --inverse-state-hover: var(--light-mode-state-hover);
    --inverse-state-idle: var(--light-mode-state-idle);
    --inverse-state-press: var(--light-mode-state-press);
    --inverse-stroke-default: var(--light-mode-stroke-default);
    --inverse-stroke-emphasis: var(--light-mode-stroke-emphasis);
    --inverse-stroke-muted: var(--light-mode-stroke-muted);
    --inverse-surface-0: var(--light-mode-surface-0);
    --inverse-surface-100: var(--light-mode-surface-100);
    --inverse-surface-200: var(--light-mode-surface-200);
    --inverse-surface-300: var(--light-mode-surface-300);
    --inverse-system-alert: var(--light-mode-system-alert);
    --inverse-system-contrast: var(--light-mode-system-contrast);
    --inverse-system-emphasis: var(--light-mode-system-emphasis);
    --inverse-system-neutral: var(--light-mode-system-neutral);
    --inverse-system-success: var(--light-mode-system-success);
    --inverse-system-warning: var(--light-mode-system-warning);
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --lightningcss-light: ;
      --lightningcss-dark: initial;
    }
  }
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.aspect-1-1 {
  aspect-ratio: 1;
}

.aspect-1-2 {
  aspect-ratio: 1 / 2;
}

.aspect-16-9 {
  aspect-ratio: 16 / 9;
}

.aspect-2-1 {
  aspect-ratio: 2;
}

.aspect-2-3 {
  aspect-ratio: 2 / 3;
}

.aspect-3-2 {
  aspect-ratio: 3 / 2;
}

.aspect-3-4 {
  aspect-ratio: 3 / 4;
}

.aspect-4-3 {
  aspect-ratio: 4 / 3;
}

.aspect-4-5 {
  aspect-ratio: 4 / 5;
}

.aspect-5-4 {
  aspect-ratio: 5 / 4;
}

.aspect-9-16 {
  aspect-ratio: 9 / 16;
}

.basis-0 {
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
}

.basis-auto {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.place-content-center {
  -ms-flex-line-pack: center;
  -ms-flex-pack: center;
  align-content: center;
  justify-content: center;
}

.place-content-start {
  -ms-flex-line-pack: start;
  -ms-flex-pack: start;
  align-content: start;
  justify-content: start;
}

.place-content-end {
  -ms-flex-line-pack: end;
  -ms-flex-pack: end;
  align-content: end;
  justify-content: end;
}

.place-content-between {
  -ms-flex-line-pack: justify;
  -ms-flex-pack: justify;
  align-content: space-between;
  justify-content: space-between;
}

.place-content-around {
  -ms-flex-line-pack: distribute;
  -ms-flex-pack: distribute;
  align-content: space-around;
  justify-content: space-around;
}

.place-content-evenly {
  align-content: space-evenly;
  justify-content: space-evenly;
}

.place-content-baseline {
  -ms-flex-pack: start;
  align-content: baseline;
  justify-content: start;
}

.place-content-stretch {
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  justify-content: stretch;
}

.place-items-start {
  -ms-flex-align: start;
  align-items: start;
  justify-items: start;
}

.place-items-end {
  -ms-flex-align: end;
  align-items: end;
  justify-items: end;
}

.place-items-center {
  -ms-flex-align: center;
  align-items: center;
  justify-items: center;
}

.place-items-baseline {
  align-items: baseline;
  justify-items: baseline;
}

.place-items-stretch {
  -ms-flex-align: stretch;
  align-items: stretch;
  justify-items: stretch;
}

.items-start {
  -ms-flex-align: start;
  align-items: flex-start;
}

.items-end {
  -ms-flex-align: end;
  align-items: flex-end;
}

.items-center {
  -ms-flex-align: center;
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  -ms-flex-align: stretch;
  align-items: stretch;
}

.justify-normal {
  justify-content: normal;
}

.justify-start {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-end {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-between {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.place-self-auto {
  -ms-flex-item-align: auto;
  align-self: auto;
  justify-self: auto;
}

.place-self-start {
  -ms-flex-item-align: start;
  align-self: start;
  justify-self: start;
}

.place-self-end {
  -ms-flex-item-align: end;
  align-self: end;
  justify-self: end;
}

.place-self-center {
  -ms-flex-item-align: center;
  align-self: center;
  justify-self: center;
}

.place-self-stretch {
  -ms-flex-item-align: stretch;
  align-self: stretch;
  justify-self: stretch;
}

.self-auto {
  -ms-flex-item-align: auto;
  align-self: auto;
}

.self-start {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.self-center {
  -ms-flex-item-align: center;
  align-self: center;
}

.self-stretch {
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.self-baseline {
  align-self: baseline;
}

.justify-self-auto {
  justify-self: auto;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-stretch {
  justify-self: stretch;
}

.light-theme {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
  --color-action-alert-background: var(--light-mode-action-alert-background);
  --color-action-alert-border: var(--light-mode-action-alert-border);
  --color-action-alert-foreground: var(--light-mode-action-alert-foreground);
  --color-action-emphasis-background: var(--light-mode-action-emphasis-background);
  --color-action-emphasis-border: var(--light-mode-action-emphasis-border);
  --color-action-emphasis-foreground: var(--light-mode-action-emphasis-foreground);
  --color-action-link-background: var(--light-mode-action-link-background);
  --color-action-link-border: var(--light-mode-action-link-border);
  --color-action-link-foreground: var(--light-mode-action-link-foreground);
  --color-action-soft-emphasis-background: var(--light-mode-action-soft-emphasis-background);
  --color-action-soft-emphasis-border: var(--light-mode-action-soft-emphasis-border);
  --color-action-soft-emphasis-foreground: var(--light-mode-action-soft-emphasis-foreground);
  --color-action-standard-background: var(--light-mode-action-standard-background);
  --color-action-standard-border: var(--light-mode-action-standard-border);
  --color-action-standard-foreground: var(--light-mode-action-standard-foreground);
  --color-action-sub-emphasis-background: var(--light-mode-action-sub-emphasis-background);
  --color-action-sub-emphasis-border: var(--light-mode-action-sub-emphasis-border);
  --color-action-sub-emphasis-foreground: var(--light-mode-action-sub-emphasis-foreground);
  --color-action-subtle-background: var(--light-mode-action-subtle-background);
  --color-action-subtle-border: var(--light-mode-action-subtle-border);
  --color-action-subtle-foreground: var(--light-mode-action-subtle-foreground);
  --color-common-heavy-divider: var(--light-mode-common-heavy-divider);
  --color-common-navigation-bar: var(--light-mode-common-navigation-bar);
  --color-common-scrim: var(--light-mode-common-scrim);
  --color-common-shadow: var(--light-mode-common-shadow);
  --color-common-shimmer: var(--light-mode-common-shimmer);
  --color-content-default: var(--light-mode-content-default);
  --color-content-emphasis: var(--light-mode-content-emphasis);
  --color-content-link: var(--light-mode-content-link);
  --color-content-muted: var(--light-mode-content-muted);
  --color-none: var(--light-mode-none);
  --color-over-media-0: var(--light-mode-over-media-0);
  --color-over-media-100: var(--light-mode-over-media-100);
  --color-over-media-200: var(--light-mode-over-media-200);
  --color-over-media-300: var(--light-mode-over-media-300);
  --color-selection-end: var(--light-mode-selection-end);
  --color-selection-start: var(--light-mode-selection-start);
  --color-shift-100: var(--light-mode-shift-100);
  --color-shift-200: var(--light-mode-shift-200);
  --color-shift-300: var(--light-mode-shift-300);
  --color-shift-400: var(--light-mode-shift-400);
  --color-state-hover: var(--light-mode-state-hover);
  --color-state-idle: var(--light-mode-state-idle);
  --color-state-press: var(--light-mode-state-press);
  --color-stroke-default: var(--light-mode-stroke-default);
  --color-stroke-emphasis: var(--light-mode-stroke-emphasis);
  --color-stroke-muted: var(--light-mode-stroke-muted);
  --color-surface-0: var(--light-mode-surface-0);
  --color-surface-100: var(--light-mode-surface-100);
  --color-surface-200: var(--light-mode-surface-200);
  --color-surface-300: var(--light-mode-surface-300);
  --color-system-alert: var(--light-mode-system-alert);
  --color-system-contrast: var(--light-mode-system-contrast);
  --color-system-emphasis: var(--light-mode-system-emphasis);
  --color-system-neutral: var(--light-mode-system-neutral);
  --color-system-success: var(--light-mode-system-success);
  --color-system-warning: var(--light-mode-system-warning);
  --config-color-mode-is-dark: false;
  --config-color-mode-is-light: true;
  --config-color-mode-name: Light;
  --inverse-action-alert-background: var(--dark-mode-action-alert-background);
  --inverse-action-alert-border: var(--dark-mode-action-alert-border);
  --inverse-action-alert-foreground: var(--dark-mode-action-alert-foreground);
  --inverse-action-emphasis-background: var(--dark-mode-action-emphasis-background);
  --inverse-action-emphasis-border: var(--dark-mode-action-emphasis-border);
  --inverse-action-emphasis-foreground: var(--dark-mode-action-emphasis-foreground);
  --inverse-action-link-background: var(--dark-mode-action-link-background);
  --inverse-action-link-border: var(--dark-mode-action-link-border);
  --inverse-action-link-foreground: var(--dark-mode-action-link-foreground);
  --inverse-action-soft-emphasis-background: var(--dark-mode-action-soft-emphasis-background);
  --inverse-action-soft-emphasis-border: var(--dark-mode-action-soft-emphasis-border);
  --inverse-action-soft-emphasis-foreground: var(--dark-mode-action-soft-emphasis-foreground);
  --inverse-action-standard-background: var(--dark-mode-action-standard-background);
  --inverse-action-standard-border: var(--dark-mode-action-standard-border);
  --inverse-action-standard-foreground: var(--dark-mode-action-standard-foreground);
  --inverse-action-sub-emphasis-background: var(--dark-mode-action-sub-emphasis-background);
  --inverse-action-sub-emphasis-border: var(--dark-mode-action-sub-emphasis-border);
  --inverse-action-sub-emphasis-foreground: var(--dark-mode-action-sub-emphasis-foreground);
  --inverse-action-subtle-background: var(--dark-mode-action-subtle-background);
  --inverse-action-subtle-border: var(--dark-mode-action-subtle-border);
  --inverse-action-subtle-foreground: var(--dark-mode-action-subtle-foreground);
  --inverse-common-heavy-divider: var(--dark-mode-common-heavy-divider);
  --inverse-common-navigation-bar: var(--dark-mode-common-navigation-bar);
  --inverse-common-scrim: var(--dark-mode-common-scrim);
  --inverse-common-shadow: var(--dark-mode-common-shadow);
  --inverse-common-shimmer: var(--dark-mode-common-shimmer);
  --inverse-content-default: var(--dark-mode-content-default);
  --inverse-content-emphasis: var(--dark-mode-content-emphasis);
  --inverse-content-link: var(--dark-mode-content-link);
  --inverse-content-muted: var(--dark-mode-content-muted);
  --inverse-none: var(--dark-mode-none);
  --inverse-over-media-0: var(--dark-mode-over-media-0);
  --inverse-over-media-100: var(--dark-mode-over-media-100);
  --inverse-over-media-200: var(--dark-mode-over-media-200);
  --inverse-over-media-300: var(--dark-mode-over-media-300);
  --inverse-selection-end: var(--dark-mode-selection-end);
  --inverse-selection-start: var(--dark-mode-selection-start);
  --inverse-shift-100: var(--dark-mode-shift-100);
  --inverse-shift-200: var(--dark-mode-shift-200);
  --inverse-shift-300: var(--dark-mode-shift-300);
  --inverse-shift-400: var(--dark-mode-shift-400);
  --inverse-state-hover: var(--dark-mode-state-hover);
  --inverse-state-idle: var(--dark-mode-state-idle);
  --inverse-state-press: var(--dark-mode-state-press);
  --inverse-stroke-default: var(--dark-mode-stroke-default);
  --inverse-stroke-emphasis: var(--dark-mode-stroke-emphasis);
  --inverse-stroke-muted: var(--dark-mode-stroke-muted);
  --inverse-surface-0: var(--dark-mode-surface-0);
  --inverse-surface-100: var(--dark-mode-surface-100);
  --inverse-surface-200: var(--dark-mode-surface-200);
  --inverse-surface-300: var(--dark-mode-surface-300);
  --inverse-system-alert: var(--dark-mode-system-alert);
  --inverse-system-contrast: var(--dark-mode-system-contrast);
  --inverse-system-emphasis: var(--dark-mode-system-emphasis);
  --inverse-system-neutral: var(--dark-mode-system-neutral);
  --inverse-system-success: var(--dark-mode-system-success);
  --inverse-system-warning: var(--dark-mode-system-warning);
}

.dark-theme {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
  --color-action-alert-background: var(--dark-mode-action-alert-background);
  --color-action-alert-border: var(--dark-mode-action-alert-border);
  --color-action-alert-foreground: var(--dark-mode-action-alert-foreground);
  --color-action-emphasis-background: var(--dark-mode-action-emphasis-background);
  --color-action-emphasis-border: var(--dark-mode-action-emphasis-border);
  --color-action-emphasis-foreground: var(--dark-mode-action-emphasis-foreground);
  --color-action-link-background: var(--dark-mode-action-link-background);
  --color-action-link-border: var(--dark-mode-action-link-border);
  --color-action-link-foreground: var(--dark-mode-action-link-foreground);
  --color-action-soft-emphasis-background: var(--dark-mode-action-soft-emphasis-background);
  --color-action-soft-emphasis-border: var(--dark-mode-action-soft-emphasis-border);
  --color-action-soft-emphasis-foreground: var(--dark-mode-action-soft-emphasis-foreground);
  --color-action-standard-background: var(--dark-mode-action-standard-background);
  --color-action-standard-border: var(--dark-mode-action-standard-border);
  --color-action-standard-foreground: var(--dark-mode-action-standard-foreground);
  --color-action-sub-emphasis-background: var(--dark-mode-action-sub-emphasis-background);
  --color-action-sub-emphasis-border: var(--dark-mode-action-sub-emphasis-border);
  --color-action-sub-emphasis-foreground: var(--dark-mode-action-sub-emphasis-foreground);
  --color-action-subtle-background: var(--dark-mode-action-subtle-background);
  --color-action-subtle-border: var(--dark-mode-action-subtle-border);
  --color-action-subtle-foreground: var(--dark-mode-action-subtle-foreground);
  --color-common-heavy-divider: var(--dark-mode-common-heavy-divider);
  --color-common-navigation-bar: var(--dark-mode-common-navigation-bar);
  --color-common-scrim: var(--dark-mode-common-scrim);
  --color-common-shadow: var(--dark-mode-common-shadow);
  --color-common-shimmer: var(--dark-mode-common-shimmer);
  --color-content-default: var(--dark-mode-content-default);
  --color-content-emphasis: var(--dark-mode-content-emphasis);
  --color-content-link: var(--dark-mode-content-link);
  --color-content-muted: var(--dark-mode-content-muted);
  --color-none: var(--dark-mode-none);
  --color-over-media-0: var(--dark-mode-over-media-0);
  --color-over-media-100: var(--dark-mode-over-media-100);
  --color-over-media-200: var(--dark-mode-over-media-200);
  --color-over-media-300: var(--dark-mode-over-media-300);
  --color-selection-end: var(--dark-mode-selection-end);
  --color-selection-start: var(--dark-mode-selection-start);
  --color-shift-100: var(--dark-mode-shift-100);
  --color-shift-200: var(--dark-mode-shift-200);
  --color-shift-300: var(--dark-mode-shift-300);
  --color-shift-400: var(--dark-mode-shift-400);
  --color-state-hover: var(--dark-mode-state-hover);
  --color-state-idle: var(--dark-mode-state-idle);
  --color-state-press: var(--dark-mode-state-press);
  --color-stroke-default: var(--dark-mode-stroke-default);
  --color-stroke-emphasis: var(--dark-mode-stroke-emphasis);
  --color-stroke-muted: var(--dark-mode-stroke-muted);
  --color-surface-0: var(--dark-mode-surface-0);
  --color-surface-100: var(--dark-mode-surface-100);
  --color-surface-200: var(--dark-mode-surface-200);
  --color-surface-300: var(--dark-mode-surface-300);
  --color-system-alert: var(--dark-mode-system-alert);
  --color-system-contrast: var(--dark-mode-system-contrast);
  --color-system-emphasis: var(--dark-mode-system-emphasis);
  --color-system-neutral: var(--dark-mode-system-neutral);
  --color-system-success: var(--dark-mode-system-success);
  --color-system-warning: var(--dark-mode-system-warning);
  --config-color-mode-is-dark: true;
  --config-color-mode-is-light: false;
  --config-color-mode-name: Dark;
  --inverse-action-alert-background: var(--light-mode-action-alert-background);
  --inverse-action-alert-border: var(--light-mode-action-alert-border);
  --inverse-action-alert-foreground: var(--light-mode-action-alert-foreground);
  --inverse-action-emphasis-background: var(--light-mode-action-emphasis-background);
  --inverse-action-emphasis-border: var(--light-mode-action-emphasis-border);
  --inverse-action-emphasis-foreground: var(--light-mode-action-emphasis-foreground);
  --inverse-action-link-background: var(--light-mode-action-link-background);
  --inverse-action-link-border: var(--light-mode-action-link-border);
  --inverse-action-link-foreground: var(--light-mode-action-link-foreground);
  --inverse-action-soft-emphasis-background: var(--light-mode-action-soft-emphasis-background);
  --inverse-action-soft-emphasis-border: var(--light-mode-action-soft-emphasis-border);
  --inverse-action-soft-emphasis-foreground: var(--light-mode-action-soft-emphasis-foreground);
  --inverse-action-standard-background: var(--light-mode-action-standard-background);
  --inverse-action-standard-border: var(--light-mode-action-standard-border);
  --inverse-action-standard-foreground: var(--light-mode-action-standard-foreground);
  --inverse-action-sub-emphasis-background: var(--light-mode-action-sub-emphasis-background);
  --inverse-action-sub-emphasis-border: var(--light-mode-action-sub-emphasis-border);
  --inverse-action-sub-emphasis-foreground: var(--light-mode-action-sub-emphasis-foreground);
  --inverse-action-subtle-background: var(--light-mode-action-subtle-background);
  --inverse-action-subtle-border: var(--light-mode-action-subtle-border);
  --inverse-action-subtle-foreground: var(--light-mode-action-subtle-foreground);
  --inverse-common-heavy-divider: var(--light-mode-common-heavy-divider);
  --inverse-common-navigation-bar: var(--light-mode-common-navigation-bar);
  --inverse-common-scrim: var(--light-mode-common-scrim);
  --inverse-common-shadow: var(--light-mode-common-shadow);
  --inverse-common-shimmer: var(--light-mode-common-shimmer);
  --inverse-content-default: var(--light-mode-content-default);
  --inverse-content-emphasis: var(--light-mode-content-emphasis);
  --inverse-content-link: var(--light-mode-content-link);
  --inverse-content-muted: var(--light-mode-content-muted);
  --inverse-none: var(--light-mode-none);
  --inverse-over-media-0: var(--light-mode-over-media-0);
  --inverse-over-media-100: var(--light-mode-over-media-100);
  --inverse-over-media-200: var(--light-mode-over-media-200);
  --inverse-over-media-300: var(--light-mode-over-media-300);
  --inverse-selection-end: var(--light-mode-selection-end);
  --inverse-selection-start: var(--light-mode-selection-start);
  --inverse-shift-100: var(--light-mode-shift-100);
  --inverse-shift-200: var(--light-mode-shift-200);
  --inverse-shift-300: var(--light-mode-shift-300);
  --inverse-shift-400: var(--light-mode-shift-400);
  --inverse-state-hover: var(--light-mode-state-hover);
  --inverse-state-idle: var(--light-mode-state-idle);
  --inverse-state-press: var(--light-mode-state-press);
  --inverse-stroke-default: var(--light-mode-stroke-default);
  --inverse-stroke-emphasis: var(--light-mode-stroke-emphasis);
  --inverse-stroke-muted: var(--light-mode-stroke-muted);
  --inverse-surface-0: var(--light-mode-surface-0);
  --inverse-surface-100: var(--light-mode-surface-100);
  --inverse-surface-200: var(--light-mode-surface-200);
  --inverse-surface-300: var(--light-mode-surface-300);
  --inverse-system-alert: var(--light-mode-system-alert);
  --inverse-system-contrast: var(--light-mode-system-contrast);
  --inverse-system-emphasis: var(--light-mode-system-emphasis);
  --inverse-system-neutral: var(--light-mode-system-neutral);
  --inverse-system-success: var(--light-mode-system-success);
  --inverse-system-warning: var(--light-mode-system-warning);
}

.system-theme {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light dark;
  --color-action-alert-background: var(--light-mode-action-alert-background);
  --color-action-alert-border: var(--light-mode-action-alert-border);
  --color-action-alert-foreground: var(--light-mode-action-alert-foreground);
  --color-action-emphasis-background: var(--light-mode-action-emphasis-background);
  --color-action-emphasis-border: var(--light-mode-action-emphasis-border);
  --color-action-emphasis-foreground: var(--light-mode-action-emphasis-foreground);
  --color-action-link-background: var(--light-mode-action-link-background);
  --color-action-link-border: var(--light-mode-action-link-border);
  --color-action-link-foreground: var(--light-mode-action-link-foreground);
  --color-action-soft-emphasis-background: var(--light-mode-action-soft-emphasis-background);
  --color-action-soft-emphasis-border: var(--light-mode-action-soft-emphasis-border);
  --color-action-soft-emphasis-foreground: var(--light-mode-action-soft-emphasis-foreground);
  --color-action-standard-background: var(--light-mode-action-standard-background);
  --color-action-standard-border: var(--light-mode-action-standard-border);
  --color-action-standard-foreground: var(--light-mode-action-standard-foreground);
  --color-action-sub-emphasis-background: var(--light-mode-action-sub-emphasis-background);
  --color-action-sub-emphasis-border: var(--light-mode-action-sub-emphasis-border);
  --color-action-sub-emphasis-foreground: var(--light-mode-action-sub-emphasis-foreground);
  --color-action-subtle-background: var(--light-mode-action-subtle-background);
  --color-action-subtle-border: var(--light-mode-action-subtle-border);
  --color-action-subtle-foreground: var(--light-mode-action-subtle-foreground);
  --color-common-heavy-divider: var(--light-mode-common-heavy-divider);
  --color-common-navigation-bar: var(--light-mode-common-navigation-bar);
  --color-common-scrim: var(--light-mode-common-scrim);
  --color-common-shadow: var(--light-mode-common-shadow);
  --color-common-shimmer: var(--light-mode-common-shimmer);
  --color-content-default: var(--light-mode-content-default);
  --color-content-emphasis: var(--light-mode-content-emphasis);
  --color-content-link: var(--light-mode-content-link);
  --color-content-muted: var(--light-mode-content-muted);
  --color-none: var(--light-mode-none);
  --color-over-media-0: var(--light-mode-over-media-0);
  --color-over-media-100: var(--light-mode-over-media-100);
  --color-over-media-200: var(--light-mode-over-media-200);
  --color-over-media-300: var(--light-mode-over-media-300);
  --color-selection-end: var(--light-mode-selection-end);
  --color-selection-start: var(--light-mode-selection-start);
  --color-shift-100: var(--light-mode-shift-100);
  --color-shift-200: var(--light-mode-shift-200);
  --color-shift-300: var(--light-mode-shift-300);
  --color-shift-400: var(--light-mode-shift-400);
  --color-state-hover: var(--light-mode-state-hover);
  --color-state-idle: var(--light-mode-state-idle);
  --color-state-press: var(--light-mode-state-press);
  --color-stroke-default: var(--light-mode-stroke-default);
  --color-stroke-emphasis: var(--light-mode-stroke-emphasis);
  --color-stroke-muted: var(--light-mode-stroke-muted);
  --color-surface-0: var(--light-mode-surface-0);
  --color-surface-100: var(--light-mode-surface-100);
  --color-surface-200: var(--light-mode-surface-200);
  --color-surface-300: var(--light-mode-surface-300);
  --color-system-alert: var(--light-mode-system-alert);
  --color-system-contrast: var(--light-mode-system-contrast);
  --color-system-emphasis: var(--light-mode-system-emphasis);
  --color-system-neutral: var(--light-mode-system-neutral);
  --color-system-success: var(--light-mode-system-success);
  --color-system-warning: var(--light-mode-system-warning);
  --config-color-mode-is-dark: false;
  --config-color-mode-is-light: true;
  --config-color-mode-name: Light;
  --inverse-action-alert-background: var(--dark-mode-action-alert-background);
  --inverse-action-alert-border: var(--dark-mode-action-alert-border);
  --inverse-action-alert-foreground: var(--dark-mode-action-alert-foreground);
  --inverse-action-emphasis-background: var(--dark-mode-action-emphasis-background);
  --inverse-action-emphasis-border: var(--dark-mode-action-emphasis-border);
  --inverse-action-emphasis-foreground: var(--dark-mode-action-emphasis-foreground);
  --inverse-action-link-background: var(--dark-mode-action-link-background);
  --inverse-action-link-border: var(--dark-mode-action-link-border);
  --inverse-action-link-foreground: var(--dark-mode-action-link-foreground);
  --inverse-action-soft-emphasis-background: var(--dark-mode-action-soft-emphasis-background);
  --inverse-action-soft-emphasis-border: var(--dark-mode-action-soft-emphasis-border);
  --inverse-action-soft-emphasis-foreground: var(--dark-mode-action-soft-emphasis-foreground);
  --inverse-action-standard-background: var(--dark-mode-action-standard-background);
  --inverse-action-standard-border: var(--dark-mode-action-standard-border);
  --inverse-action-standard-foreground: var(--dark-mode-action-standard-foreground);
  --inverse-action-sub-emphasis-background: var(--dark-mode-action-sub-emphasis-background);
  --inverse-action-sub-emphasis-border: var(--dark-mode-action-sub-emphasis-border);
  --inverse-action-sub-emphasis-foreground: var(--dark-mode-action-sub-emphasis-foreground);
  --inverse-action-subtle-background: var(--dark-mode-action-subtle-background);
  --inverse-action-subtle-border: var(--dark-mode-action-subtle-border);
  --inverse-action-subtle-foreground: var(--dark-mode-action-subtle-foreground);
  --inverse-common-heavy-divider: var(--dark-mode-common-heavy-divider);
  --inverse-common-navigation-bar: var(--dark-mode-common-navigation-bar);
  --inverse-common-scrim: var(--dark-mode-common-scrim);
  --inverse-common-shadow: var(--dark-mode-common-shadow);
  --inverse-common-shimmer: var(--dark-mode-common-shimmer);
  --inverse-content-default: var(--dark-mode-content-default);
  --inverse-content-emphasis: var(--dark-mode-content-emphasis);
  --inverse-content-link: var(--dark-mode-content-link);
  --inverse-content-muted: var(--dark-mode-content-muted);
  --inverse-none: var(--dark-mode-none);
  --inverse-over-media-0: var(--dark-mode-over-media-0);
  --inverse-over-media-100: var(--dark-mode-over-media-100);
  --inverse-over-media-200: var(--dark-mode-over-media-200);
  --inverse-over-media-300: var(--dark-mode-over-media-300);
  --inverse-selection-end: var(--dark-mode-selection-end);
  --inverse-selection-start: var(--dark-mode-selection-start);
  --inverse-shift-100: var(--dark-mode-shift-100);
  --inverse-shift-200: var(--dark-mode-shift-200);
  --inverse-shift-300: var(--dark-mode-shift-300);
  --inverse-shift-400: var(--dark-mode-shift-400);
  --inverse-state-hover: var(--dark-mode-state-hover);
  --inverse-state-idle: var(--dark-mode-state-idle);
  --inverse-state-press: var(--dark-mode-state-press);
  --inverse-stroke-default: var(--dark-mode-stroke-default);
  --inverse-stroke-emphasis: var(--dark-mode-stroke-emphasis);
  --inverse-stroke-muted: var(--dark-mode-stroke-muted);
  --inverse-surface-0: var(--dark-mode-surface-0);
  --inverse-surface-100: var(--dark-mode-surface-100);
  --inverse-surface-200: var(--dark-mode-surface-200);
  --inverse-surface-300: var(--dark-mode-surface-300);
  --inverse-system-alert: var(--dark-mode-system-alert);
  --inverse-system-contrast: var(--dark-mode-system-contrast);
  --inverse-system-emphasis: var(--dark-mode-system-emphasis);
  --inverse-system-neutral: var(--dark-mode-system-neutral);
  --inverse-system-success: var(--dark-mode-system-success);
  --inverse-system-warning: var(--dark-mode-system-warning);
}

@media (prefers-color-scheme: dark) {
  .system-theme {
    --lightningcss-light: initial;
    --lightningcss-dark: ;
    --lightningcss-light: initial;
    --lightningcss-dark: ;
    color-scheme: light dark;
    --color-action-alert-background: var(--dark-mode-action-alert-background);
    --color-action-alert-border: var(--dark-mode-action-alert-border);
    --color-action-alert-foreground: var(--dark-mode-action-alert-foreground);
    --color-action-emphasis-background: var(--dark-mode-action-emphasis-background);
    --color-action-emphasis-border: var(--dark-mode-action-emphasis-border);
    --color-action-emphasis-foreground: var(--dark-mode-action-emphasis-foreground);
    --color-action-link-background: var(--dark-mode-action-link-background);
    --color-action-link-border: var(--dark-mode-action-link-border);
    --color-action-link-foreground: var(--dark-mode-action-link-foreground);
    --color-action-soft-emphasis-background: var(--dark-mode-action-soft-emphasis-background);
    --color-action-soft-emphasis-border: var(--dark-mode-action-soft-emphasis-border);
    --color-action-soft-emphasis-foreground: var(--dark-mode-action-soft-emphasis-foreground);
    --color-action-standard-background: var(--dark-mode-action-standard-background);
    --color-action-standard-border: var(--dark-mode-action-standard-border);
    --color-action-standard-foreground: var(--dark-mode-action-standard-foreground);
    --color-action-sub-emphasis-background: var(--dark-mode-action-sub-emphasis-background);
    --color-action-sub-emphasis-border: var(--dark-mode-action-sub-emphasis-border);
    --color-action-sub-emphasis-foreground: var(--dark-mode-action-sub-emphasis-foreground);
    --color-action-subtle-background: var(--dark-mode-action-subtle-background);
    --color-action-subtle-border: var(--dark-mode-action-subtle-border);
    --color-action-subtle-foreground: var(--dark-mode-action-subtle-foreground);
    --color-common-heavy-divider: var(--dark-mode-common-heavy-divider);
    --color-common-navigation-bar: var(--dark-mode-common-navigation-bar);
    --color-common-scrim: var(--dark-mode-common-scrim);
    --color-common-shadow: var(--dark-mode-common-shadow);
    --color-common-shimmer: var(--dark-mode-common-shimmer);
    --color-content-default: var(--dark-mode-content-default);
    --color-content-emphasis: var(--dark-mode-content-emphasis);
    --color-content-link: var(--dark-mode-content-link);
    --color-content-muted: var(--dark-mode-content-muted);
    --color-none: var(--dark-mode-none);
    --color-over-media-0: var(--dark-mode-over-media-0);
    --color-over-media-100: var(--dark-mode-over-media-100);
    --color-over-media-200: var(--dark-mode-over-media-200);
    --color-over-media-300: var(--dark-mode-over-media-300);
    --color-selection-end: var(--dark-mode-selection-end);
    --color-selection-start: var(--dark-mode-selection-start);
    --color-shift-100: var(--dark-mode-shift-100);
    --color-shift-200: var(--dark-mode-shift-200);
    --color-shift-300: var(--dark-mode-shift-300);
    --color-shift-400: var(--dark-mode-shift-400);
    --color-state-hover: var(--dark-mode-state-hover);
    --color-state-idle: var(--dark-mode-state-idle);
    --color-state-press: var(--dark-mode-state-press);
    --color-stroke-default: var(--dark-mode-stroke-default);
    --color-stroke-emphasis: var(--dark-mode-stroke-emphasis);
    --color-stroke-muted: var(--dark-mode-stroke-muted);
    --color-surface-0: var(--dark-mode-surface-0);
    --color-surface-100: var(--dark-mode-surface-100);
    --color-surface-200: var(--dark-mode-surface-200);
    --color-surface-300: var(--dark-mode-surface-300);
    --color-system-alert: var(--dark-mode-system-alert);
    --color-system-contrast: var(--dark-mode-system-contrast);
    --color-system-emphasis: var(--dark-mode-system-emphasis);
    --color-system-neutral: var(--dark-mode-system-neutral);
    --color-system-success: var(--dark-mode-system-success);
    --color-system-warning: var(--dark-mode-system-warning);
    --config-color-mode-is-dark: true;
    --config-color-mode-is-light: false;
    --config-color-mode-name: Dark;
    --inverse-action-alert-background: var(--light-mode-action-alert-background);
    --inverse-action-alert-border: var(--light-mode-action-alert-border);
    --inverse-action-alert-foreground: var(--light-mode-action-alert-foreground);
    --inverse-action-emphasis-background: var(--light-mode-action-emphasis-background);
    --inverse-action-emphasis-border: var(--light-mode-action-emphasis-border);
    --inverse-action-emphasis-foreground: var(--light-mode-action-emphasis-foreground);
    --inverse-action-link-background: var(--light-mode-action-link-background);
    --inverse-action-link-border: var(--light-mode-action-link-border);
    --inverse-action-link-foreground: var(--light-mode-action-link-foreground);
    --inverse-action-soft-emphasis-background: var(--light-mode-action-soft-emphasis-background);
    --inverse-action-soft-emphasis-border: var(--light-mode-action-soft-emphasis-border);
    --inverse-action-soft-emphasis-foreground: var(--light-mode-action-soft-emphasis-foreground);
    --inverse-action-standard-background: var(--light-mode-action-standard-background);
    --inverse-action-standard-border: var(--light-mode-action-standard-border);
    --inverse-action-standard-foreground: var(--light-mode-action-standard-foreground);
    --inverse-action-sub-emphasis-background: var(--light-mode-action-sub-emphasis-background);
    --inverse-action-sub-emphasis-border: var(--light-mode-action-sub-emphasis-border);
    --inverse-action-sub-emphasis-foreground: var(--light-mode-action-sub-emphasis-foreground);
    --inverse-action-subtle-background: var(--light-mode-action-subtle-background);
    --inverse-action-subtle-border: var(--light-mode-action-subtle-border);
    --inverse-action-subtle-foreground: var(--light-mode-action-subtle-foreground);
    --inverse-common-heavy-divider: var(--light-mode-common-heavy-divider);
    --inverse-common-navigation-bar: var(--light-mode-common-navigation-bar);
    --inverse-common-scrim: var(--light-mode-common-scrim);
    --inverse-common-shadow: var(--light-mode-common-shadow);
    --inverse-common-shimmer: var(--light-mode-common-shimmer);
    --inverse-content-default: var(--light-mode-content-default);
    --inverse-content-emphasis: var(--light-mode-content-emphasis);
    --inverse-content-link: var(--light-mode-content-link);
    --inverse-content-muted: var(--light-mode-content-muted);
    --inverse-none: var(--light-mode-none);
    --inverse-over-media-0: var(--light-mode-over-media-0);
    --inverse-over-media-100: var(--light-mode-over-media-100);
    --inverse-over-media-200: var(--light-mode-over-media-200);
    --inverse-over-media-300: var(--light-mode-over-media-300);
    --inverse-selection-end: var(--light-mode-selection-end);
    --inverse-selection-start: var(--light-mode-selection-start);
    --inverse-shift-100: var(--light-mode-shift-100);
    --inverse-shift-200: var(--light-mode-shift-200);
    --inverse-shift-300: var(--light-mode-shift-300);
    --inverse-shift-400: var(--light-mode-shift-400);
    --inverse-state-hover: var(--light-mode-state-hover);
    --inverse-state-idle: var(--light-mode-state-idle);
    --inverse-state-press: var(--light-mode-state-press);
    --inverse-stroke-default: var(--light-mode-stroke-default);
    --inverse-stroke-emphasis: var(--light-mode-stroke-emphasis);
    --inverse-stroke-muted: var(--light-mode-stroke-muted);
    --inverse-surface-0: var(--light-mode-surface-0);
    --inverse-surface-100: var(--light-mode-surface-100);
    --inverse-surface-200: var(--light-mode-surface-200);
    --inverse-surface-300: var(--light-mode-surface-300);
    --inverse-system-alert: var(--light-mode-system-alert);
    --inverse-system-contrast: var(--light-mode-system-contrast);
    --inverse-system-emphasis: var(--light-mode-system-emphasis);
    --inverse-system-neutral: var(--light-mode-system-neutral);
    --inverse-system-success: var(--light-mode-system-success);
    --inverse-system-warning: var(--light-mode-system-warning);
  }

  @media (prefers-color-scheme: dark) {
    .system-theme {
      --lightningcss-light: ;
      --lightningcss-dark: initial;
    }
  }
}

.bg-action-alert {
  background-color: var(--color-action-alert-background);
}

.bg-action-emphasis {
  background-color: var(--color-action-emphasis-background);
}

.bg-action-link {
  background-color: var(--color-action-link-background);
}

.bg-action-soft-emphasis {
  background-color: var(--color-action-soft-emphasis-background);
}

.bg-action-standard {
  background-color: var(--color-action-standard-background);
}

.bg-action-sub-emphasis {
  background-color: var(--color-action-sub-emphasis-background);
}

.bg-action-subtle {
  background-color: var(--color-action-subtle-background);
}

.bg-none {
  background-color: var(--color-none);
}

.bg-over-media-0 {
  background-color: var(--color-over-media-0);
}

.bg-over-media-100 {
  background-color: var(--color-over-media-100);
}

.bg-over-media-200 {
  background-color: var(--color-over-media-200);
}

.bg-over-media-300 {
  background-color: var(--color-over-media-300);
}

.bg-shift-100 {
  background-color: var(--color-shift-100);
}

.bg-shift-200 {
  background-color: var(--color-shift-200);
}

.bg-shift-300 {
  background-color: var(--color-shift-300);
}

.bg-shift-400 {
  background-color: var(--color-shift-400);
}

.bg-surface-0 {
  background-color: var(--color-surface-0);
}

.bg-surface-100 {
  background-color: var(--color-surface-100);
}

.bg-surface-200 {
  background-color: var(--color-surface-200);
}

.bg-surface-300 {
  background-color: var(--color-surface-300);
}

.bg-system-alert {
  background-color: var(--color-system-alert);
}

.bg-system-contrast {
  background-color: var(--color-system-contrast);
}

.bg-system-emphasis {
  background-color: var(--color-system-emphasis);
}

.bg-system-neutral {
  background-color: var(--color-system-neutral);
}

.bg-system-success {
  background-color: var(--color-system-success);
}

.bg-system-warning {
  background-color: var(--color-system-warning);
}

.content-action-alert {
  color: var(--color-action-alert-foreground);
}

.content-action-emphasis {
  color: var(--color-action-emphasis-foreground);
}

.content-action-link {
  color: var(--color-action-link-foreground);
}

.content-action-soft-emphasis {
  color: var(--color-action-soft-emphasis-foreground);
}

.content-action-standard {
  color: var(--color-action-standard-foreground);
}

.content-action-sub-emphasis {
  color: var(--color-action-sub-emphasis-foreground);
}

.content-action-subtle {
  color: var(--color-action-subtle-foreground);
}

.content-default {
  color: var(--color-content-default);
}

.content-emphasis {
  color: var(--color-content-emphasis);
}

.content-link {
  color: var(--color-content-link);
}

.content-muted {
  color: var(--color-content-muted);
}

.content-system-alert {
  color: var(--color-system-alert);
}

.content-system-contrast {
  color: var(--color-system-contrast);
}

.content-system-emphasis {
  color: var(--color-system-emphasis);
}

.content-system-neutral {
  color: var(--color-system-neutral);
}

.content-system-success {
  color: var(--color-system-success);
}

.content-system-warning {
  color: var(--color-system-warning);
}

.stroke-action-alert {
  border-color: var(--color-action-alert-border);
}

.stroke-action-emphasis {
  border-color: var(--color-action-emphasis-border);
}

.stroke-action-link {
  border-color: var(--color-action-link-border);
}

.stroke-action-soft-emphasis {
  border-color: var(--color-action-soft-emphasis-border);
}

.stroke-action-standard {
  border-color: var(--color-action-standard-border);
}

.stroke-action-sub-emphasis {
  border-color: var(--color-action-sub-emphasis-border);
}

.stroke-action-subtle {
  border-color: var(--color-action-subtle-border);
}

.stroke-default {
  border-color: var(--color-stroke-default);
}

.stroke-emphasis {
  border-color: var(--color-stroke-emphasis);
}

.stroke-muted {
  border-color: var(--color-stroke-muted);
}

.stroke-system-alert {
  border-color: var(--color-system-alert);
}

.stroke-system-contrast {
  border-color: var(--color-system-contrast);
}

.stroke-system-emphasis {
  border-color: var(--color-system-emphasis);
}

.stroke-system-neutral {
  border-color: var(--color-system-neutral);
}

.stroke-system-success {
  border-color: var(--color-system-success);
}

.stroke-system-warning {
  border-color: var(--color-system-warning);
}

.text-body-large {
  font: var(--typography-body-large-font);
  letter-spacing: var(--typography-body-large-letter-spacing);
}

.text-body-medium {
  font: var(--typography-body-medium-font);
  letter-spacing: var(--typography-body-medium-letter-spacing);
}

.text-body-small {
  font: var(--typography-body-small-font);
  letter-spacing: var(--typography-body-small-letter-spacing);
}

.text-caption-large {
  font: var(--typography-caption-large-font);
  letter-spacing: var(--typography-caption-large-letter-spacing);
}

.text-caption-medium {
  font: var(--typography-caption-medium-font);
  letter-spacing: var(--typography-caption-medium-letter-spacing);
}

.text-caption-small {
  font: var(--typography-caption-small-font);
  letter-spacing: var(--typography-caption-small-letter-spacing);
}

.text-display-large {
  font: var(--typography-display-large-font);
  letter-spacing: var(--typography-display-large-letter-spacing);
}

.text-display-medium {
  font: var(--typography-display-medium-font);
  letter-spacing: var(--typography-display-medium-letter-spacing);
}

.text-display-small {
  font: var(--typography-display-small-font);
  letter-spacing: var(--typography-display-small-letter-spacing);
}

.text-heading-large {
  font: var(--typography-heading-large-font);
  letter-spacing: var(--typography-heading-large-letter-spacing);
}

.text-heading-medium {
  font: var(--typography-heading-medium-font);
  letter-spacing: var(--typography-heading-medium-letter-spacing);
}

.text-heading-small {
  font: var(--typography-heading-small-font);
  letter-spacing: var(--typography-heading-small-letter-spacing);
}

.text-label-large {
  font: var(--typography-label-large-font);
  letter-spacing: var(--typography-label-large-letter-spacing);
}

.text-label-medium {
  font: var(--typography-label-medium-font);
  letter-spacing: var(--typography-label-medium-letter-spacing);
}

.text-label-small {
  font: var(--typography-label-small-font);
  letter-spacing: var(--typography-label-small-letter-spacing);
}

.text-title-large {
  font: var(--typography-title-large-font);
  letter-spacing: var(--typography-title-large-letter-spacing);
}

.text-title-medium {
  font: var(--typography-title-medium-font);
  letter-spacing: var(--typography-title-medium-letter-spacing);
}

.text-title-small {
  font: var(--typography-title-small-font);
  letter-spacing: var(--typography-title-small-letter-spacing);
}

.text-wrap {
  white-space: normal;
}

.text-no-wrap {
  white-space: nowrap;
}

.text-truncate-split, .text-truncate-end {
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-truncate-none {
  text-overflow: clip;
  overflow: visible;
}

.text-align-x-center {
  text-align: center;
}

.text-align-x-left {
  text-align: left;
}

.text-align-x-right {
  text-align: right;
}

.text-align-y-bottom {
  vertical-align: text-bottom;
}

.text-align-y-center {
  vertical-align: middle;
}

.text-align-y-top {
  vertical-align: text-top;
}

.height-0 {
  height: var(--size-0);
}

.height-100 {
  height: var(--size-100);
}

.height-1000 {
  height: var(--size-1000);
}

.height-1100 {
  height: var(--size-1100);
}

.height-1200 {
  height: var(--size-1200);
}

.height-1300 {
  height: var(--size-1300);
}

.height-1400 {
  height: var(--size-1400);
}

.height-150 {
  height: var(--size-150);
}

.height-1500 {
  height: var(--size-1500);
}

.height-1600 {
  height: var(--size-1600);
}

.height-1700 {
  height: var(--size-1700);
}

.height-1800 {
  height: var(--size-1800);
}

.height-1900 {
  height: var(--size-1900);
}

.height-200 {
  height: var(--size-200);
}

.height-2000 {
  height: var(--size-2000);
}

.height-2100 {
  height: var(--size-2100);
}

.height-2200 {
  height: var(--size-2200);
}

.height-2300 {
  height: var(--size-2300);
}

.height-2400 {
  height: var(--size-2400);
}

.height-250 {
  height: var(--size-250);
}

.height-2500 {
  height: var(--size-2500);
}

.height-2600 {
  height: var(--size-2600);
}

.height-2700 {
  height: var(--size-2700);
}

.height-2800 {
  height: var(--size-2800);
}

.height-2900 {
  height: var(--size-2900);
}

.height-300 {
  height: var(--size-300);
}

.height-3000 {
  height: var(--size-3000);
}

.height-350 {
  height: var(--size-350);
}

.height-400 {
  height: var(--size-400);
}

.height-50 {
  height: var(--size-50);
}

.height-500 {
  height: var(--size-500);
}

.height-600 {
  height: var(--size-600);
}

.height-700 {
  height: var(--size-700);
}

.height-800 {
  height: var(--size-800);
}

.height-900 {
  height: var(--size-900);
}

.height-full {
  height: 100%;
}

.size-0 {
  width: var(--size-0);
  height: var(--size-0);
}

.size-100 {
  width: var(--size-100);
  height: var(--size-100);
}

.size-1000 {
  width: var(--size-1000);
  height: var(--size-1000);
}

.size-1100 {
  width: var(--size-1100);
  height: var(--size-1100);
}

.size-1200 {
  width: var(--size-1200);
  height: var(--size-1200);
}

.size-1300 {
  width: var(--size-1300);
  height: var(--size-1300);
}

.size-1400 {
  width: var(--size-1400);
  height: var(--size-1400);
}

.size-150 {
  width: var(--size-150);
  height: var(--size-150);
}

.size-1500 {
  width: var(--size-1500);
  height: var(--size-1500);
}

.size-1600 {
  width: var(--size-1600);
  height: var(--size-1600);
}

.size-1700 {
  width: var(--size-1700);
  height: var(--size-1700);
}

.size-1800 {
  width: var(--size-1800);
  height: var(--size-1800);
}

.size-1900 {
  width: var(--size-1900);
  height: var(--size-1900);
}

.size-200 {
  width: var(--size-200);
  height: var(--size-200);
}

.size-2000 {
  width: var(--size-2000);
  height: var(--size-2000);
}

.size-2100 {
  width: var(--size-2100);
  height: var(--size-2100);
}

.size-2200 {
  width: var(--size-2200);
  height: var(--size-2200);
}

.size-2300 {
  width: var(--size-2300);
  height: var(--size-2300);
}

.size-2400 {
  width: var(--size-2400);
  height: var(--size-2400);
}

.size-250 {
  width: var(--size-250);
  height: var(--size-250);
}

.size-2500 {
  width: var(--size-2500);
  height: var(--size-2500);
}

.size-2600 {
  width: var(--size-2600);
  height: var(--size-2600);
}

.size-2700 {
  width: var(--size-2700);
  height: var(--size-2700);
}

.size-2800 {
  width: var(--size-2800);
  height: var(--size-2800);
}

.size-2900 {
  width: var(--size-2900);
  height: var(--size-2900);
}

.size-300 {
  width: var(--size-300);
  height: var(--size-300);
}

.size-3000 {
  width: var(--size-3000);
  height: var(--size-3000);
}

.size-350 {
  width: var(--size-350);
  height: var(--size-350);
}

.size-400 {
  width: var(--size-400);
  height: var(--size-400);
}

.size-50 {
  width: var(--size-50);
  height: var(--size-50);
}

.size-500 {
  width: var(--size-500);
  height: var(--size-500);
}

.size-600 {
  width: var(--size-600);
  height: var(--size-600);
}

.size-700 {
  width: var(--size-700);
  height: var(--size-700);
}

.size-800 {
  width: var(--size-800);
  height: var(--size-800);
}

.size-900 {
  width: var(--size-900);
  height: var(--size-900);
}

.size-full {
  width: 100%;
  height: 100%;
}

.width-0 {
  width: var(--size-0);
}

.width-100 {
  width: var(--size-100);
}

.width-1000 {
  width: var(--size-1000);
}

.width-1100 {
  width: var(--size-1100);
}

.width-1200 {
  width: var(--size-1200);
}

.width-1300 {
  width: var(--size-1300);
}

.width-1400 {
  width: var(--size-1400);
}

.width-150 {
  width: var(--size-150);
}

.width-1500 {
  width: var(--size-1500);
}

.width-1600 {
  width: var(--size-1600);
}

.width-1700 {
  width: var(--size-1700);
}

.width-1800 {
  width: var(--size-1800);
}

.width-1900 {
  width: var(--size-1900);
}

.width-200 {
  width: var(--size-200);
}

.width-2000 {
  width: var(--size-2000);
}

.width-2100 {
  width: var(--size-2100);
}

.width-2200 {
  width: var(--size-2200);
}

.width-2300 {
  width: var(--size-2300);
}

.width-2400 {
  width: var(--size-2400);
}

.width-250 {
  width: var(--size-250);
}

.width-2500 {
  width: var(--size-2500);
}

.width-2600 {
  width: var(--size-2600);
}

.width-2700 {
  width: var(--size-2700);
}

.width-2800 {
  width: var(--size-2800);
}

.width-2900 {
  width: var(--size-2900);
}

.width-300 {
  width: var(--size-300);
}

.width-3000 {
  width: var(--size-3000);
}

.width-350 {
  width: var(--size-350);
}

.width-400 {
  width: var(--size-400);
}

.width-50 {
  width: var(--size-50);
}

.width-500 {
  width: var(--size-500);
}

.width-600 {
  width: var(--size-600);
}

.width-700 {
  width: var(--size-700);
}

.width-800 {
  width: var(--size-800);
}

.width-900 {
  width: var(--size-900);
}

.width-full {
  width: 100%;
}

.padding-bottom-large {
  padding-bottom: var(--padding-large);
}

.padding-bottom-medium {
  padding-bottom: var(--padding-medium);
}

.padding-bottom-small {
  padding-bottom: var(--padding-small);
}

.padding-bottom-xlarge {
  padding-bottom: var(--padding-xlarge);
}

.padding-bottom-xsmall {
  padding-bottom: var(--padding-xsmall);
}

.padding-bottom-xxlarge {
  padding-bottom: var(--padding-xxlarge);
}

.padding-bottom-xxsmall {
  padding-bottom: var(--padding-xxsmall);
}

.padding-large {
  padding: var(--padding-large);
}

.padding-left-large {
  padding-left: var(--padding-large);
}

.padding-left-medium {
  padding-left: var(--padding-medium);
}

.padding-left-small {
  padding-left: var(--padding-small);
}

.padding-left-xlarge {
  padding-left: var(--padding-xlarge);
}

.padding-left-xsmall {
  padding-left: var(--padding-xsmall);
}

.padding-left-xxlarge {
  padding-left: var(--padding-xxlarge);
}

.padding-left-xxsmall {
  padding-left: var(--padding-xxsmall);
}

.padding-medium {
  padding: var(--padding-medium);
}

.padding-right-large {
  padding-right: var(--padding-large);
}

.padding-right-medium {
  padding-right: var(--padding-medium);
}

.padding-right-small {
  padding-right: var(--padding-small);
}

.padding-right-xlarge {
  padding-right: var(--padding-xlarge);
}

.padding-right-xsmall {
  padding-right: var(--padding-xsmall);
}

.padding-right-xxlarge {
  padding-right: var(--padding-xxlarge);
}

.padding-right-xxsmall {
  padding-right: var(--padding-xxsmall);
}

.padding-small {
  padding: var(--padding-small);
}

.padding-top-large {
  padding-top: var(--padding-large);
}

.padding-top-medium {
  padding-top: var(--padding-medium);
}

.padding-top-small {
  padding-top: var(--padding-small);
}

.padding-top-xlarge {
  padding-top: var(--padding-xlarge);
}

.padding-top-xsmall {
  padding-top: var(--padding-xsmall);
}

.padding-top-xxlarge {
  padding-top: var(--padding-xxlarge);
}

.padding-top-xxsmall {
  padding-top: var(--padding-xxsmall);
}

.padding-x-large {
  padding-left: var(--padding-large);
  padding-right: var(--padding-large);
}

.padding-x-medium {
  padding-left: var(--padding-medium);
  padding-right: var(--padding-medium);
}

.padding-x-small {
  padding-left: var(--padding-small);
  padding-right: var(--padding-small);
}

.padding-x-xlarge {
  padding-left: var(--padding-xlarge);
  padding-right: var(--padding-xlarge);
}

.padding-x-xsmall {
  padding-left: var(--padding-xsmall);
  padding-right: var(--padding-xsmall);
}

.padding-x-xxlarge {
  padding-left: var(--padding-xxlarge);
  padding-right: var(--padding-xxlarge);
}

.padding-x-xxsmall {
  padding-left: var(--padding-xxsmall);
  padding-right: var(--padding-xxsmall);
}

.padding-xlarge {
  padding: var(--padding-xlarge);
}

.padding-xsmall {
  padding: var(--padding-xsmall);
}

.padding-xxlarge {
  padding: var(--padding-xxlarge);
}

.padding-xxsmall {
  padding: var(--padding-xxsmall);
}

.padding-y-large {
  padding-top: var(--padding-large);
  padding-bottom: var(--padding-large);
}

.padding-y-medium {
  padding-top: var(--padding-medium);
  padding-bottom: var(--padding-medium);
}

.padding-y-small {
  padding-top: var(--padding-small);
  padding-bottom: var(--padding-small);
}

.padding-y-xlarge {
  padding-top: var(--padding-xlarge);
  padding-bottom: var(--padding-xlarge);
}

.padding-y-xsmall {
  padding-top: var(--padding-xsmall);
  padding-bottom: var(--padding-xsmall);
}

.padding-y-xxlarge {
  padding-top: var(--padding-xxlarge);
  padding-bottom: var(--padding-xxlarge);
}

.padding-y-xxsmall {
  padding-top: var(--padding-xxsmall);
  padding-bottom: var(--padding-xxsmall);
}

.margin-bottom-large {
  margin-bottom: var(--margin-large);
}

.margin-bottom-medium {
  margin-bottom: var(--margin-medium);
}

.margin-bottom-small {
  margin-bottom: var(--margin-small);
}

.margin-large {
  margin: var(--margin-large);
}

.margin-left-large {
  margin-left: var(--margin-large);
}

.margin-left-medium {
  margin-left: var(--margin-medium);
}

.margin-left-small {
  margin-left: var(--margin-small);
}

.margin-medium {
  margin: var(--margin-medium);
}

.margin-right-large {
  margin-right: var(--margin-large);
}

.margin-right-medium {
  margin-right: var(--margin-medium);
}

.margin-right-small {
  margin-right: var(--margin-small);
}

.margin-small {
  margin: var(--margin-small);
}

.margin-top-large {
  margin-top: var(--margin-large);
}

.margin-top-medium {
  margin-top: var(--margin-medium);
}

.margin-top-small {
  margin-top: var(--margin-small);
}

.margin-x-large {
  margin-left: var(--margin-large);
  margin-right: var(--margin-large);
}

.margin-x-medium {
  margin-left: var(--margin-medium);
  margin-right: var(--margin-medium);
}

.margin-x-small {
  margin-left: var(--margin-small);
  margin-right: var(--margin-small);
}

.margin-y-large {
  margin-top: var(--margin-large);
  margin-bottom: var(--margin-large);
}

.margin-y-medium {
  margin-top: var(--margin-medium);
  margin-bottom: var(--margin-medium);
}

.margin-y-small {
  margin-top: var(--margin-small);
  margin-bottom: var(--margin-small);
}

.gap-large {
  gap: var(--gap-large);
}

.gap-medium {
  gap: var(--gap-medium);
}

.gap-small {
  gap: var(--gap-small);
}

.gap-x-large {
  -moz-column-gap: var(--gap-large);
  column-gap: var(--gap-large);
}

.gap-x-medium {
  -moz-column-gap: var(--gap-medium);
  column-gap: var(--gap-medium);
}

.gap-x-small {
  -moz-column-gap: var(--gap-small);
  column-gap: var(--gap-small);
}

.gap-x-xlarge {
  -moz-column-gap: var(--gap-xlarge);
  column-gap: var(--gap-xlarge);
}

.gap-x-xsmall {
  -moz-column-gap: var(--gap-xsmall);
  column-gap: var(--gap-xsmall);
}

.gap-x-xxlarge {
  -moz-column-gap: var(--gap-xxlarge);
  column-gap: var(--gap-xxlarge);
}

.gap-x-xxsmall {
  -moz-column-gap: var(--gap-xxsmall);
  column-gap: var(--gap-xxsmall);
}

.gap-xlarge {
  gap: var(--gap-xlarge);
}

.gap-xsmall {
  gap: var(--gap-xsmall);
}

.gap-xxlarge {
  gap: var(--gap-xxlarge);
}

.gap-xxsmall {
  gap: var(--gap-xxsmall);
}

.gap-y-large {
  row-gap: var(--gap-large);
}

.gap-y-medium {
  row-gap: var(--gap-medium);
}

.gap-y-small {
  row-gap: var(--gap-small);
}

.gap-y-xlarge {
  row-gap: var(--gap-xlarge);
}

.gap-y-xsmall {
  row-gap: var(--gap-xsmall);
}

.gap-y-xxlarge {
  row-gap: var(--gap-xxlarge);
}

.gap-y-xxsmall {
  row-gap: var(--gap-xxsmall);
}

.gutter-large {
  gap: var(--gutter-large);
}

.gutter-medium {
  gap: var(--gutter-medium);
}

.gutter-small {
  gap: var(--gutter-small);
}

.gutter-x-large {
  -moz-column-gap: var(--gutter-large);
  column-gap: var(--gutter-large);
}

.gutter-x-medium {
  -moz-column-gap: var(--gutter-medium);
  column-gap: var(--gutter-medium);
}

.gutter-x-small {
  -moz-column-gap: var(--gutter-small);
  column-gap: var(--gutter-small);
}

.gutter-x-xlarge {
  -moz-column-gap: var(--gutter-xlarge);
  column-gap: var(--gutter-xlarge);
}

.gutter-x-xxlarge {
  -moz-column-gap: var(--gutter-xxlarge);
  column-gap: var(--gutter-xxlarge);
}

.gutter-xlarge {
  gap: var(--gutter-xlarge);
}

.gutter-xxlarge {
  gap: var(--gutter-xxlarge);
}

.gutter-y-large {
  row-gap: var(--gutter-large);
}

.gutter-y-medium {
  row-gap: var(--gutter-medium);
}

.gutter-y-small {
  row-gap: var(--gutter-small);
}

.gutter-y-xlarge {
  row-gap: var(--gutter-xlarge);
}

.gutter-y-xxlarge {
  row-gap: var(--gutter-xxlarge);
}

.stroke-none {
  box-sizing: border-box;
  border: 0 solid;
}

.stroke-standard {
  border: solid var(--stroke-standard);
  box-sizing: border-box;
}

.stroke-thick {
  border: solid var(--stroke-thick);
  box-sizing: border-box;
}

.stroke-thicker {
  border: solid var(--stroke-thicker);
  box-sizing: border-box;
}

.stroke-thin {
  border: solid var(--stroke-thin);
  box-sizing: border-box;
}

.radius-circle {
  border-radius: var(--radius-circle);
}

.radius-large {
  border-radius: var(--radius-large);
}

.radius-medium {
  border-radius: var(--radius-medium);
}

.radius-none {
  border-radius: var(--radius-none);
}

.radius-small {
  border-radius: var(--radius-small);
}

.radius-xsmall {
  border-radius: var(--radius-xsmall);
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: -ms-flexbox;
  display: flex;
}

.inline-flex {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.clip {
  overflow: hidden;
}

.clip-x {
  overflow-x: hidden;
}

.clip-y {
  overflow-y: hidden;
}

.no-clip {
  overflow: visible;
}

.no-clip-x {
  overflow-x: visible;
}

.no-clip-y {
  overflow-y: visible;
}

.scroll {
  overflow: auto;
}

.scroll-x {
  overflow-x: auto;
}

.scroll-y {
  overflow-y: auto;
}

.grow {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.shrink {
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.fill {
  -ms-flex: auto;
  flex: auto;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.no-wrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.flex-row {
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-col {
  -ms-flex-direction: column;
  flex-direction: column;
}

.align-content-around {
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.align-content-baseline {
  align-content: baseline;
}

.align-content-between {
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.align-content-center {
  -ms-flex-line-pack: center;
  align-content: center;
}

.align-content-end {
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.align-content-evenly {
  align-content: space-evenly;
}

.align-content-normal {
  align-content: normal;
}

.align-content-start {
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.grow-0 {
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.grow-1 {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.grow-2 {
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.grow-3 {
  -ms-flex-positive: 3;
  flex-grow: 3;
}

.grow-4 {
  -ms-flex-positive: 4;
  flex-grow: 4;
}

.grow-5 {
  -ms-flex-positive: 5;
  flex-grow: 5;
}

.shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.shrink-2 {
  -ms-flex-negative: 2;
  flex-shrink: 2;
}

.shrink-3 {
  -ms-flex-negative: 3;
  flex-shrink: 3;
}

.shrink-4 {
  -ms-flex-negative: 4;
  flex-shrink: 4;
}

.shrink-5 {
  -ms-flex-negative: 5;
  flex-shrink: 5;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.hidden {
  display: none;
}
